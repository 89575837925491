import styled from 'styled-components'
import { Box } from 'ui/core'

export const AccountSwitch = styled(Box)`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  > * {
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    > * {
      color: ${({ theme }) => theme.colors.primary300};
      transition: all 0.3s ease-in-out;
    }
  }
`
