import {
  ThemeColorProp,
  ThemeFontSizeProp,
  ThemeResponsiveProp,
} from 'ui/theme'
import { createElement, FC } from 'react'
import { IconType as ReactIconType } from 'react-icons/lib'
import { StyledBox } from '../box/box.styles'
import {
  FiClock,
  FiHeart,
  FiShoppingCart,
  FiSearch,
  FiInfo,
  FiUser,
  FiArrowLeft,
  FiArrowRight,
  FiChevronDown,
  FiX,
  FiCheck,
  FiChevronRight,
} from 'react-icons/fi'
import { IoLogoFacebook, IoLogoInstagram, IoLogoYoutube } from 'react-icons/io'

export type IconMapper<T extends string> = Record<T, ReactIconType>

export const ALL_ICONS = [
  'time',
  'info',
  'user',
  'heart',
  'cart',
  'search',
  'close',
  'arrowLeft',
  'arrowRight',
  'chevronDown',
  'chevronRight',
  'checkmark',
  'facebook',
  'instagram',
  'youtube',
] as const
export type IconType = typeof ALL_ICONS[number]

const iconMapper: IconMapper<IconType> = {
  time: FiClock,
  info: FiInfo,
  user: FiUser,
  heart: FiHeart,
  cart: FiShoppingCart,
  search: FiSearch,
  arrowLeft: FiArrowLeft,
  arrowRight: FiArrowRight,
  chevronDown: FiChevronDown,
  chevronRight: FiChevronRight,
  close: FiX,
  checkmark: FiCheck,
  facebook: IoLogoFacebook,
  instagram: IoLogoInstagram,
  youtube: IoLogoYoutube,
}

export type IconProps = {
  type: IconType
  color?: ThemeColorProp
  size?: ThemeResponsiveProp<ThemeFontSizeProp>
}

export const Icon: FC<IconProps> = ({ type, color, size, ...props }) => {
  const icon = iconMapper[type]

  return (
    <StyledBox
      as="span"
      display="inline-flex"
      height="100%"
      alignItems="center"
      color={color || 'icon'}
      fontSize={size}
    >
      {createElement(icon, props)}
    </StyledBox>
  )
}
