import styled from 'styled-components'
import { Box } from 'ui/core'
import { media } from 'ui/utils/lib/mediaQuery'

export const InputWrapper = styled(Box)`
  input {
    width: 100%;
    ${media.desktopSmallUp} {
      min-width: 500px;
    }
  }
`
