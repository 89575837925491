import { ThemeProvider } from 'styled-components'
import { getTheme } from '../ui/theme'
import { ThemeVariant } from 'types'
import { Search } from 'components/search'

export const SearchApp = ({
  title,
  site,
  initialQuery,
}: {
  title: string
  site: ThemeVariant
  initialQuery: string
}) => {
  const theme = getTheme(site)
  return (
    <ThemeProvider theme={theme}>
      <Search title={title} initialQuery={initialQuery} />
    </ThemeProvider>
  )
}
