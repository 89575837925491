import { FC, useEffect, useState } from 'react'
import {
  FadeInContent,
  MenuExpandableAnchor,
  StyledMenu,
} from 'components/menu/menu.styles'
import AnimateHeight from 'react-animate-height'
import { ThemeVariant } from 'types'
import { Anchor, Box, Button, Hidden, Icon, Text } from 'ui/core'
import { Account } from 'types/account'
import axios from 'axios'

interface Props {
  theme: ThemeVariant
  accounts: Account[] | null
  userName: string
  open: boolean
  accountsLoading: boolean
}
export const MobileUserMenu: FC<Props> = ({
  theme,
  accounts,
  userName,
  open,
  accountsLoading,
}) => {
  const [height, setHeight] = useState<string | number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!open && height !== 0) setHeight(0)
    else if (open && height === 0) setHeight('auto')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const logout = async () => {
    setLoading(true)
    axios.post(`/umbraco/surface/login/logout`).then(() => {
      window.location.reload()
      setLoading(false)
    })
  }

  const switchAccount = async (selectedAccount: string) => {
    setLoading(true)
    axios
      .post(`/umbraco/api/account/SetAccount`, { selectedAccount })
      .then(() => {
        window.location.reload()
        setLoading(false)
      })
  }

  return (
    <StyledMenu
      position="absolute"
      top={0}
      left={0}
      zIndex="-1"
      width="full"
      bg="white"
      boxShadow="menu"
      autoFocus={open ? true : false}
    >
      <AnimateHeight duration={400} height={height}>
        <Box
          width="full"
          height="viewportHeight"
          overflow="scroll"
          display="flex"
          flexWrap="nowrap"
          justifyContent="flex-start"
          alignItems="stretch"
          position="relative"
        >
          <FadeInContent
            width="full"
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            height="viewportHeight"
          >
            <Box
              width="full"
              justifyContent="space-between"
              pt={6}
              pb={2}
              px={2}
            >
              <Box py={3} my={2} borderTop="light" borderBottom="light">
                <Box width="full" display="flex" justifyContent="center">
                  <Text
                    mb={0}
                    variant="textIntro"
                    color="primary300"
                    fontWeight="medium"
                  >
                    Hæ, {userName}
                  </Text>
                </Box>
              </Box>
              <MenuExpandableAnchor
                pt={8}
                href="https://vidskiptavefur.husa.is/"
              >
                <Box width="full" display="flex">
                  <Box
                    width="full"
                    flexGrow={1}
                    p={2}
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Text
                      mb={0}
                      variant="textIntro"
                      color="dark500"
                      fontWeight="medium"
                    >
                      Þjónustuvefur
                    </Text>
                  </Box>
                </Box>
              </MenuExpandableAnchor>
              <MenuExpandableAnchor
                href={
                  theme === ThemeVariant.BLOMA
                    ? '/blomaval/verslanir/'
                    : '/upplysingar/afgreidslutimi/'
                }
              >
                <Box width="full" display="flex">
                  <Box
                    width="full"
                    flexGrow={1}
                    p={2}
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Text
                      mb={0}
                      variant="textIntro"
                      color="dark500"
                      fontWeight="medium"
                    >
                      Afgreiðslutímar
                    </Text>
                  </Box>
                </Box>
              </MenuExpandableAnchor>
              <MenuExpandableAnchor href="https://vidskiptavefur.husa.is/">
                <Box width="full" display="flex">
                  <Box
                    width="full"
                    flexGrow={1}
                    p={2}
                    alignItems="center"
                    justifyContent="space-between"
                    display="flex"
                  >
                    <Text
                      mb={0}
                      variant="textIntro"
                      color="dark500"
                      fontWeight="medium"
                    >
                      Hafa samband
                    </Text>
                  </Box>
                </Box>
              </MenuExpandableAnchor>
              <Box
                py={4}
                width="full"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {accountsLoading ||
                  (accounts && accounts.length > 0 && (
                    <Box width="full">
                      <Box
                        pb={1}
                        px={4}
                        mb={1}
                        width="full"
                        borderBottom="light"
                      >
                        <Anchor
                          fontWeight="black"
                          href="https://vidskiptavefur.husa.is/"
                        >
                          Mínir reikningar
                        </Anchor>
                      </Box>
                      {accountsLoading ? (
                        <Box>Sæki reikninga...</Box>
                      ) : (
                        accounts.map((account, index) => (
                          <Box
                            pl={1}
                            key={account.SSN}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            onClick={() =>
                              !account.Selected
                                ? switchAccount(account.AccountNum)
                                : null
                            }
                          >
                            <Box display="flex">
                              <Box
                                height="full"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                py={1}
                                pr={1}
                                opacity={account.Selected ? 1 : 0}
                              >
                                <Icon
                                  type="checkmark"
                                  size="18"
                                  color="primary300"
                                />
                              </Box>
                              <Text
                                fontWeight={
                                  account.Selected ? 'medium' : 'regular'
                                }
                                py={1}
                                m={0}
                                color={account.Selected ? 'dark500' : 'dark300'}
                              >
                                {account.SSN}
                              </Text>
                            </Box>
                            <Hidden below="sm">
                              <Text
                                fontWeight={
                                  account.Selected ? 'medium' : 'regular'
                                }
                                color={account.Selected ? 'dark500' : 'dark300'}
                                py={1}
                                pr={4}
                                m={0}
                              >
                                {account.Category.length >= 30
                                  ? account.Category.slice(0, 28) + '...'
                                  : account.Category}
                              </Text>
                            </Hidden>
                          </Box>
                        ))
                      )}
                    </Box>
                  ))}
              </Box>
              <Box pt={6} display="flex" width="full" justifyContent="center">
                <Button onClick={logout} size="large" variant="blue">
                  {loading ? '...' : 'Útskráning'}
                </Button>
              </Box>
            </Box>
          </FadeInContent>
        </Box>
      </AnimateHeight>
    </StyledMenu>
  )
}
