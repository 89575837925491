import { FooterColumnProps } from './footerColumn'

const GeneralInformationColumnHusa: FooterColumnProps = {
  figure: 'logoHusa',
  phone: '525 3000',
  title: 'Húsasmiðjan',
  links: [
    { text: 'Kjalarvogi 12-14, 104 Reykjavík' },
    { text: 'Kt: 551211-0290' },
    { text: 'Vsknúmer: 109741' },
  ],
}

const GeneralInformationColumnBloma: FooterColumnProps = {
  figure: 'logoBloma',
  phone: '525 3180',
  title: 'Blómaval',
  links: [
    { text: 'Kjalarvogi 12-14, 104 Reykjavík' },
    { text: 'Kt: 551211-0290' },
    { text: 'Vsknúmer: 109741' },
  ],
}
const GeneralInformationColumnIskraft: FooterColumnProps = {
  figure: 'logoHusa', // TODO
  phone: '535 1200',
  title: 'Ískraft',
  links: [
    { text: 'Smiðjuvegi 5, 200 Kópavogi' },
    { text: 'Kt: 551211-0290' },
    { text: 'Vsknúmer: 109832' },
  ],
}

export const GeneralInformationColumn = {
  husa: GeneralInformationColumnHusa,
  bloma: GeneralInformationColumnBloma,
  iskraft: GeneralInformationColumnIskraft,
}

export const SocialMediaColumn: FooterColumnProps = {
  title: 'Fylgdu okkur',
  links: [
    {
      text: 'Facebook',
      icon: 'facebook',
      url: 'https://www.facebook.com/Husasmidjan.Bygma?fref=ts',
    },
    {
      text: 'Instagram',
      icon: 'instagram',
      url: 'https://www.instagram.com/husasmidjan/',
    },
    {
      text: 'YouTube',
      icon: 'youtube',
      url: 'https://www.youtube.com/user/husamyndbond',
    },
  ],
}
