import styled from 'styled-components'
import { Box } from 'ui/core'
import { media } from 'ui/utils/lib/mediaQuery'

export const ProductImage = styled(Box)<{ image: string }>`
  width: 65px;
  height: 60px;
  background: url(${({ image }) => 'https://www.husa.is/' + image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  ${media.customUp(2000)} {
    width: 85px;
    height: 80px;
  }
`

export const ProductCardAnchor = styled.a`
  color: ${({ theme }) => theme.colors.dark400};
  &:hover {
    color: ${({ theme }) => theme.colors.primary400};
    ${ProductImage} {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
    }
  }
`
