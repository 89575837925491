import * as colors from './colors'
import * as fonts from './fonts'
import { getBorderTheme, radii } from './borders'
import { Palette } from './palettes'

const buttonFocusStyles = (palette: Palette) => ({
  ':focus': {
    outline: `${palette.primary300} solid 2px`,
  },
  ':focus:not(:focus-visible)': {
    outline: 'none',
  },
  ':focus-visible': {
    outline: `${palette.primary400} solid 2px`,
  },
})

const buttons = (palette: Palette) => {
  const { borders } = getBorderTheme(palette)
  const buttonFocus = buttonFocusStyles(palette)
  return {
    primary: {
      backgroundColor: palette.primary300,
      color: colors.white,
      fontWeight: fonts.fontWeights.medium,
      borderRadius: radii.xlarge,
      svg: {
        color: colors.white,
      },
      ':hover': {
        backgroundColor: palette.primaryHover,
      },
      ...buttonFocus,
    },
    blue: {
      backgroundColor: colors.black,
      color: colors.white,
      fontWeight: fonts.fontWeights.medium,
      border: borders.standard,
      borderRadius: radii.xlarge,
      svg: {
        color: colors.white,
      },
      ':hover': {
        backgroundColor: palette.primaryHover,
      },
      ...buttonFocus,
    },
    ghost: {
      backgroundColor: colors.white,
      color: colors.black,
      fontWeight: fonts.fontWeights.medium,
      border: borders.standard,
      borderRadius: radii.xlarge,
      svg: {
        color: colors.black,
      },
      ':hover': {
        backgroundColor: palette.primaryHover,
        color: colors.white,
        svg: {
          color: colors.white,
        },
      },
      ...buttonFocus,
    },
  }
}

export const buttonSizes = {
  xsmall: {
    fontSize: fonts.fontSizes['14'],
    lineHeight: fonts.lineHeights['1'],
    padding: 10,
  },
  small: {
    fontSize: fonts.fontSizes['14'],
    lineHeight: fonts.lineHeights['1'],
    padding: '12px 24px',
  },
  medium: {
    fontSize: fonts.fontSizes['14'],
    lineHeight: fonts.lineHeights['1'],
    padding: '16px 28px',
  },
  large: {
    fontSize: fonts.fontSizes['14'],
    lineHeight: fonts.lineHeights['1'],
    padding: '22px 40px',
  },
  icon: {
    fontSize: fonts.fontSizes['24'],
    lineHeight: '1',
    padding: '24px 26px',
  },
}

export const getButtonStyles = (palette: Palette) => ({
  buttonSizes,
  buttons: buttons(palette),
})
