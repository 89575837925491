import styled, { css } from 'styled-components'
import { media } from 'ui/utils/lib/mediaQuery'

export const buttonStyles = css`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  height: 40px;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.colors.gray100};
  color: ${({ theme }) => theme.colors.dark400};
  cursor: pointer;
  margin-right: 10px;
  transition: color 150ms, background-color 150ms;
  font-family: ${({ theme }) => theme.fonts.standard};
  font-weight: 500;
  font-size: 1em;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary300};
    color: ${({ theme }) => theme.colors.white};
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

export const StyledPaginationWrapper = styled.div`
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;

    li {
      &.selected a {
        background-color: ${({ theme }) => theme.colors.dark400};
        color: ${({ theme }) => theme.colors.white};
      }
      &.previous a,
      &.next a {
        background-color: ${({ theme }) => theme.colors.primary400};
        color: ${({ theme }) => theme.colors.white};
      }
      ${media.mobile} {
        &:not(.previous):not(.next):not(.selected):not(.selected + li) a {
          display: none;
        }
      }
    }

    a {
      ${buttonStyles};
    }
  }
`
