import { Text, Box, Figure, Button, ImageBox } from 'ui/core';
import { Product } from 'types/product';
import { CardLink } from './productCard.styles';
import { formatCurrency } from 'helpers';

export const ProductCard = ({
  Id,
  ImageUrl,
  CurrencyString,
  OldPrice,
  Sku,
  Title,
  Url,
  postSearchResult,
}: Product & {
  postSearchResult: (productId: string) => void;
}) => (
  <Box height="full" border="light" width="full" position="relative">
    <CardLink href={Url} onClick={() => postSearchResult(Id)}>
      <Box
        width="full"
        display="flex"
        position="relative"
        justifyContent="center"
        borderBottom="light"
        pt={2}
        pb={3}
        px={3}
      >
        {ImageUrl ? (
          <ImageBox imgSrc={`https://www.husa.is${ImageUrl}`} />
        ) : (
          <Box width="full">
            <Box
              py={8}
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity={0.5}
            >
              <Figure type="logoHusa" size={[55, 60, 75]} />
            </Box>
          </Box>
        )}
        {OldPrice > 0 && OldPrice > CurrencyString && (
          <Box
            position="absolute"
            bottom={0}
            bg="primary300"
            width="full"
            px={2}
            textAlign="center"
          >
            <Text
              variant="textXSmall"
              fontWeight="medium"
              color="white"
              m={0}
              p={0}
            >
              Tilboðsverð
            </Text>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" height="full" p={3}>
        <Box width="full">
          <Text color="inherit" fontWeight="medium" p={0} m={0}>
            {Title}
          </Text>
          <Text
            color="gray150"
            fontWeight="regular"
            variant="textSmall"
          >
            Vnr {Sku}
          </Text>
        </Box>
        <Box
          width="full"
          display="flex"
          flexDirection="column"
          mt="auto"
        >
          <Box py={2}>
            <Text
              variant="h4"
              fontWeight="semiBold"
              color="primary200"
              p={0}
              m={0}
            >
              {formatCurrency(CurrencyString)} kr.
            </Text>
            {OldPrice > 0 && OldPrice > CurrencyString && (
              <Text
                color="gray150"
                fontWeight="regular"
                variant="textSmall"
              >
                {formatCurrency(OldPrice)} kr.
              </Text>
            )}
          </Box>
          <Box width="full" display="flex" flexDirection="column">
            {OldPrice > 0 && OldPrice > CurrencyString && (
              <Box
                bg="promotionColor"
                borderRadius="large"
                px={1}
                mt={2}
              >
                <Text
                  variant="textSmall"
                  color="white"
                  m={0}
                  p={0}
                >
                  -
                  {Math.round(
                    (1 - CurrencyString / OldPrice) * 100
                  )}
                  %
                </Text>
              </Box>
            )}
          </Box>
          <Box bottom={3} width="full" px={4}>
            <Button variant="primary" size="xsmall" width="full">
              Skoða vöru
            </Button>
          </Box>
        </Box>
      </Box>
    </CardLink>
  </Box>
);
