import styled from 'styled-components'
import { Box } from 'ui/core'

export const CounterWrapper = styled(Box)`
  left: 15px;
  bottom: 12px;
  width: 22px;
  height: 22px;
  padding-top: 2px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 10px;

  * {
    font-family: ${({ theme }) => theme.fonts.supporting};
    line-height: 1.1;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    bottom: 12px;
    left: 15px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    bottom: 10px;
    left: 12px;
    width: 16px;
    height: 16px;

    > * {
      font-size: 10px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    left: 8px;
    bottom: 10px;
  }
`
