import React, { FC, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { useClickAway } from 'react-use'
import { Box } from '../box/box'
import { Icon } from '../icon/icon'
import { Text } from '../text/text'
import {
  DropdownButton,
  DropdownWrapper,
  SelectionsWrapper,
  SelectionWrapper,
  DropdownText,
  IconWrapper,
  SelectionButton,
} from './dropdown.styles'

type Item = {
  title: string
  id: string
}

export type DropdownProps = {
  items: Item[]
  placeholder: string
  onSelectionChanged: (id: string) => void
  onSelectionCleared?: () => void
  selectedItem: string
}

export const Dropdown: FC<DropdownProps> = ({
  items,
  placeholder,
  selectedItem,
  onSelectionChanged,
  onSelectionCleared,
  ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const ref = useRef(null)
  useClickAway(ref, () => setIsDropdownOpen(false))

  const handleSelectionChanged = (item: Item) => {
    onSelectionChanged(item.id)
    setIsDropdownOpen(false)
  }

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  return (
    <DropdownWrapper
      {...props}
      ref={ref}
      isDropdownOpen={isDropdownOpen}
      position="relative"
      width="full"
      aria-label={placeholder}
    >
      <DropdownButton
        onClick={handleDropdownToggle}
        isDropdownOpen={isDropdownOpen}
      >
        <DropdownText>
          {selectedItem
            ? items.find((i) => i.id === selectedItem)?.title
            : placeholder}
        </DropdownText>
        <IconWrapper
          display="flex"
          alignItems="center"
          color="gray150"
          isDropdownOpen={isDropdownOpen}
        >
          <Icon type="chevronDown" />
        </IconWrapper>
      </DropdownButton>
      {selectedItem && onSelectionCleared && (
        <Box
          display="flex"
          alignItems="center"
          top={0}
          right={[35, 35, 35, 35, 8, 35]}
          mt={1}
          justifyContent="flex-end"
          p={2}
          position="absolute"
          role="button"
          zIndex="2"
          onClick={onSelectionCleared}
        >
          <Icon type="close" />
        </Box>
      )}
      <SelectionsWrapper
        isDropdownOpen={isDropdownOpen}
        position="absolute"
        width="full"
        bg="white"
      >
        <AnimateHeight height={isDropdownOpen ? 'auto' : 0} duration={400}>
          <Box px={2} pt={1}>
            {items.map((item) => (
              <SelectionWrapper key={item.id}>
                <SelectionButton onClick={() => handleSelectionChanged(item)}>
                  <Text>{item.title}</Text>
                </SelectionButton>
              </SelectionWrapper>
            ))}
          </Box>
        </AnimateHeight>
      </SelectionsWrapper>
    </DropdownWrapper>
  )
}
