import React, { useState, useRef, forwardRef } from 'react'
import { Box } from '../box/box'
import { Icon, IconType } from '../icon/icon'
import { Text } from '../text/text'
import {
  InputLabel,
  InputRequiredStar,
  InputWrapper,
  StyledInput,
} from './input.styles'

export type InputBackgroundColor = 'white' | 'blue'
export type InputVariants = 'light' | 'dark'

interface InputComponentProps {
  name: string
  value?: string | number
  defaultValue?: string | number
  id?: string
  className?: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  autoFocus?: boolean
  autoComplete?: string
  readOnly?: boolean
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  onClick?: (
    event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>,
  ) => void
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  rows?: number
  type?: 'text' | 'number' | 'email' | 'tel' | 'password'
  variant?: InputVariants
  icon?: IconType
}

export interface InputProps extends InputComponentProps {
  label?: string
  hasError?: boolean
  errorMessage?: string
  helperText?: string
  backgroundColor?: InputBackgroundColor
  textarea?: boolean
  autoComplete?: string
}

export interface StyledInputProps extends InputComponentProps {
  hasFocus: boolean
  hasError?: boolean
  autoComplete?: string
}

function setRefs<T>(ref: React.Ref<T>, value: T) {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    // eslint-disable-next-line
    ;(ref as any).current = value
  }
}

function useMergeRefs<ForwardRef, LocalRef extends ForwardRef>(
  forwardedRef: React.Ref<ForwardRef>,
  localRef: React.Ref<LocalRef>,
): (instance: LocalRef | null) => void {
  return React.useCallback(
    (value) => {
      setRefs(forwardedRef, value)
      setRefs(localRef, value)
    },
    [forwardedRef, localRef],
  )
}

const InputHOC = forwardRef(
  (props: Omit<StyledInputProps, 'size'>, ref: React.Ref<HTMLInputElement>) => (
    <StyledInput ref={ref} {...props} />
  ),
)
const TextareaHOC = forwardRef(
  (props: StyledInputProps, ref: React.Ref<HTMLTextAreaElement>) => (
    <StyledInput as="textarea" rows={4} {...props} />
  ),
)

export const Input = forwardRef(
  (
    props: InputProps,
    ref?: React.Ref<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      name,
      label,
      errorMessage = '',
      helperText,
      hasError = Boolean(errorMessage),
      value,
      defaultValue,
      id = name,
      disabled,
      required,
      placeholder,
      onFocus,
      onBlur,
      onClick,
      onKeyDown,
      textarea,
      readOnly,
      variant = 'light',
      autoComplete = 'off',
      icon,
      type,
      ...inputProps
    } = props
    const [hasFocus, setHasFocus] = useState(false)
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
    const ariaError = hasError
      ? {
          'aria-invalid': true,
          'aria-describedby': id,
        }
      : {}
    const mergedRefs = useMergeRefs(inputRef, ref || null)

    const InputComponent = textarea ? TextareaHOC : InputHOC

    const labelStandIn = label || placeholder || ''

    return (
      <div>
        <Box
          display="flex"
          alignItems="center"
          onClick={(e) => {
            e.preventDefault()
            if (inputRef.current) {
              inputRef.current.focus()
            }
          }}
        >
          <InputWrapper>
            <InputLabel
              htmlFor={id}
              disabled={disabled}
              readOnly={readOnly}
              hasError={hasError}
              hasFocus={hasFocus || (!!value && value.toString().length > 0)}
              variant={variant}
            >
              {labelStandIn}
              {required && <InputRequiredStar> *</InputRequiredStar>}
            </InputLabel>
            <InputComponent
              id={id}
              disabled={disabled || readOnly}
              readOnly={readOnly}
              name={name}
              variant={variant}
              ref={mergedRefs}
              value={value}
              defaultValue={defaultValue}
              hasFocus={hasFocus}
              hasError={hasError}
              autoComplete={autoComplete}
              placeholder={placeholder}
              onFocus={(e) => {
                setHasFocus(true)
                if (onFocus) {
                  onFocus(e)
                }
              }}
              onClick={(e) => {
                if (onClick) {
                  onClick(e)
                }
              }}
              onKeyDown={(e) => {
                if (onKeyDown) {
                  onKeyDown(e)
                }
              }}
              onBlur={(e) => {
                setHasFocus(false)
                if (onBlur) {
                  onBlur(e)
                }
              }}
              type={type}
              {...ariaError}
              {...inputProps}
            />
            {icon && (
              <Box
                position="absolute"
                top={0}
                right={3}
                bottom={0}
                display="flex"
                alignItems="center"
                borderRadius="small"
              >
                <Icon
                  type={icon}
                  size={variant === 'dark' ? '32' : '26'}
                  color={variant === 'dark' ? 'husa' : 'gray150'}
                />
              </Box>
            )}
          </InputWrapper>
        </Box>
        {hasError && errorMessage && (
          <Text variant="textSmall" mt={1} color="husa">
            {errorMessage}
          </Text>
        )}
        {helperText && (
          <Text variant="textSmall" mt={1} color="dark400">
            {helperText}
          </Text>
        )}
      </div>
    )
  },
)
