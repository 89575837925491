import { Footer, FooterColumn } from 'types'

export const provideFooterData = (): Footer => {
  const FOOTER_DATA = (window as any).__FOOTER_DATA
  const { theme, columns } = FOOTER_DATA
  var columnsParsed: FooterColumn[] = []
  try {
    columnsParsed = JSON.parse(columns)
  } catch (e) {
    // Invalid json
    console.log(e)
  }

  // Vöruflokkar column is no longer relevant
  // Skip this column if it exists in row
  columnsParsed = columnsParsed.filter((c) => c.Title !== 'Vöruflokkar')
  return {
    theme,
    columns: columnsParsed,
  }
}
