import styled, { css, keyframes } from 'styled-components'
import { Box } from 'ui/core'

const chevronMove = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`
export const Arrow = styled(Box)<{ delay: number; pushTop?: number }>`
  opacity: 0;
  transform: scale(0.8);
  animation: ${chevronMove} 2s infinite;
  animation-delay: ${({ delay }) => delay}s;
  animation-direction: alternate;
  ${({ pushTop }) =>
    pushTop &&
    css`
      margin-top: ${pushTop}px;
    `};
`
