import styled, { css } from 'styled-components'

const basicLineStyles = css`
  width: 20px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary300};
  transition: transform 200ms, opacity 200ms, width 200ms,
    background-color 200ms;
  transform-origin: center;
`

export const HamburgerLine1 = styled.div`
  ${basicLineStyles};
`

export const HamburgerLine2 = styled.div`
  ${basicLineStyles};
  margin-top: 5px;
`

export const HamburgerLine3 = styled.div`
  ${basicLineStyles};
  margin-top: 5px;
`

export const IOSPlaceholder = styled.div`
  width: 28px;
`

interface StyledHamburgerProps {
  open: boolean
}

export const StyledHamburger = styled.button<StyledHamburgerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndices.header + 1};
  padding: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: ${({ theme }) => theme.space[1]}px;
  }

  ${({ open }) =>
    open &&
    css`
      padding: 6px 0px 0px 0px;
      justify-content: center;
      ${HamburgerLine1} {
        width: 100%;
        margin-top: 0;
        transform: rotate(-45deg) translate3d(-2px, 1px, 0);
        background-color: ${({ theme }) => theme.colors.primary300};
      }

      ${HamburgerLine2} {
        opacity: 0;
      }

      ${HamburgerLine3} {
        width: 100%;
        margin-top: 0;
        transform: rotate(45deg) translate3d(-5px, -5px, 0);
        background-color: ${({ theme }) => theme.colors.primary300};
      }
    `};
`
