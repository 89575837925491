import styled, { css } from 'styled-components'
import { InputVariants, StyledInputProps } from './input'

interface LabelProps {
  hasFocus: boolean
  disabled?: boolean
  readOnly?: boolean
  hasError?: boolean
  variant?: InputVariants
}

export const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`

export const InputLabel = styled.label<LabelProps>`
  display: none;
  padding-top: 16px;
  padding-left: 15px;
  width: calc(100% - 2px);
  color: ${({ theme }) => theme.colors.dark400};
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.transparent};
  border-radius: ${({ theme }) => theme.radii.small}px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  pointer-events: none;
  transition: padding-top 250ms, font-size 250ms, line-height 250ms;

  ${({ hasFocus, theme }) =>
    hasFocus &&
    css`
      padding-top: 5px;
      font-size: ${({ theme }) => theme.fontSizes['12']}px;
      line-height: ${theme.lineHeights[1]};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.dark300};
      background-color: ${({ theme }) => theme.colors.gray150};
    `}


  ${({ readOnly }) =>
    readOnly &&
    css`
      color: ${({ theme }) => theme.colors.dark300};
      background-color: ${({ theme }) => theme.colors.gray150};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${({ theme }) => theme.colors.husa};
    `}
`

export const InputRequiredStar = styled.span``

const inputFocus = (variant?: InputVariants) => css`
  border-color: ${({ theme }) =>
    variant === 'dark'
      ? `transparent transparent ${theme.colors.primary200} transparent`
      : theme.colors.gray150};
`

const inputDisabled = css`
  background-color: ${({ theme }) => theme.colors.gray150};
  color: ${({ theme }) => theme.colors.dark300};
`

const inputReadOnly = css`
  color: ${({ theme }) => theme.colors.dark300};
`

const inputErrorState = css`
  color: ${({ theme }) => theme.colors.husa};
  border-color: ${({ theme }) => theme.colors.husaDarker};
  outline: none;
`

export const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  padding-right: ${({ theme }) => theme.space[3]}px;
  padding-bottom: 19px;
  padding-left: ${({ theme }) => theme.space[3]}px;
  padding-top: 19px;
  box-shadow: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.small}px;
  font-family: ${({ theme }) => theme.fonts.standard};
  color: ${({ theme }) => theme.colors.dark500};
  border-color: ${({ theme }) => theme.colors.transparent};
  outline: 2px solid transparent;
  border: ${({ theme }) => theme.borders.light};
  line-height: ${({ theme }) => theme.lineHeights.standard};
  transition: border 250ms, outline 250ms;

  ${({ variant }) =>
    variant === 'dark' &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary300};
      border: ${({ theme }) => theme.borders.dark};
    `}

  ${({ hasFocus, variant }) => hasFocus && inputFocus(variant)}
  ${({ disabled }) => disabled && inputDisabled}
  ${({ readOnly }) => readOnly && inputReadOnly}
  ${({ hasError }) => hasError && inputErrorState}

  &::placeholder {
    color: ${({ variant, theme }) =>
      variant === 'dark' ? theme.colors.white : theme.colors.dark300};
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`
