import styled, { keyframes } from 'styled-components'
import { Box, BoxProps } from 'ui/core'

const skeletonLoading = keyframes`
  0% {
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`

export const SkeletonLoaderWrapper = styled(Box)`
  transform: translateX(-100%);
  animation: 1.5s ${skeletonLoading} linear 0.2s infinite;
  background: linear-gradient(
    90deg,
    transparent,
    ${({ theme }) => theme.colors.gray150},
    transparent
  );
`

export const SkeletonLoader = ({
  ...props
}: Omit<BoxProps, 'overflow' | 'position' | 'bg'>) => (
  <Box bg="gray50" overflow="hidden" position="relative" {...props}>
    <SkeletonLoaderWrapper
      zIndex="1"
      position="absolute"
      height="full"
      width="full"
      top={0}
      bottom={0}
      right={0}
    />
  </Box>
)
