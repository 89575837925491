export interface Palette {
  primary200: string
  primary300: string
  primary400: string
  primaryHover: string
  primaryDisabled: string
  secondary300: string
  secondary400: string
}

const husa: Palette = {
  primary200: '#D01422',
  primary300: '#D12331',
  primary400: '#Bf1322',
  primaryHover: '#BC1F2C',
  primaryDisabled: '#CEABAE',
  secondary300: '#FACA06',
  secondary400: '#BC9806',
}
const bloma: Palette = {
  primary200: '#96C447',
  primary300: '#6A8A2A',
  primary400: '#556E22',
  primaryHover: '#506820',
  primaryDisabled: '#818776',
  secondary300: '#EA178D',
  secondary400: '#B80E6D',
}
const iskraft: Palette = {
  primary200: '#0C4dA2',
  primary300: '#0C4dA2',
  primary400: '#0A428A',
  primaryHover: '#073C81',
  primaryDisabled: '#818890',
  secondary300: '#00A994',
  secondary400: '#048A79',
}

export const AllPallettes = {
  husa,
  bloma,
  iskraft,
}
