import styled, { css } from 'styled-components'
import { Box } from '../box/box'

interface Props {
  isDropdownOpen: boolean
}

export const DropdownWrapper = styled(Box)<Props>`
  transition: box-shadow 300ms;
  border: ${({ theme }) => theme.borders.light};
  z-index: ${({ theme }) => theme.zIndices[1]};

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.standard};
  }

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    css`
      box-shadow: ${({ theme }) => theme.shadows.standard};
    `}
`

export const DropdownButton = styled.button<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  font-size: ${({ theme }) => theme.fontSizes[18]};
  outline: 0;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark300};

  &:focus-visible {
    outline: ${({ theme }) => theme.colors.black} solid 2px;
  }

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    css`
      color: ${({ theme }) => theme.colors.gray150};
    `}
`

export const IconWrapper = styled(Box)<Props>`
  transition: transform 300ms;

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    css`
      color: ${({ theme }) => theme.colors.black};
      transform: rotate(180deg);
    `}
`

export const SelectionsWrapper = styled(Box)<Props>`
  max-height: 40vh;
  overflow-y: auto;
  transition: box-shadow 300ms;

  ${({ isDropdownOpen }) =>
    isDropdownOpen &&
    css`
      box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.05);
    `}

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const SelectionWrapper = styled(Box)`
  cursor: pointer;
`

export const SelectionButton = styled.button`
  text-align: left;
  padding: 2px 5px;
  p {
    margin: 0 !important;
  }
`

export const DropdownText = styled(Box)`
  padding: 20px 0;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
