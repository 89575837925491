import React, { FC, forwardRef } from 'react'
import styled from 'styled-components'
import {
  AnchorSize,
  AnchorVariant,
  ThemeColorProp,
  ThemeFontSizeProp,
  ThemeResponsiveProp,
} from 'ui/theme'
import { BoxResponsivePaddingProps } from '../box/box'
import { Icon, IconType } from '../icon/icon'
import { Inline } from '../inline/inline'
import { StyledAnchor } from './anchor.styles'

export type AnchorProps = Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  'ref'
> & {
  fontWeight?: string
  as?: 'a' | 'button'
  iconLeft?: IconType
  iconRight?: IconType
  iconColor?: ThemeColorProp
  iconSize?: ThemeResponsiveProp<ThemeFontSizeProp>
  variant?: AnchorVariant
  size?: AnchorSize
}

export const AnchorBlock = styled.a`
  display: block;
  height: 100%;
`

export const Anchor: FC<
  AnchorProps & BoxResponsivePaddingProps & { ref?: any }
> = forwardRef<HTMLAnchorElement, AnchorProps>(
  (
    {
      as = 'a',
      size = 'standard',
      variant = 'standard',
      iconLeft,
      iconRight,
      iconSize,
      iconColor,
      fontWeight,
      children,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <StyledAnchor
        ref={forwardedRef}
        as={as}
        size={size}
        fontWeight={fontWeight}
        variant={variant}
        {...props}
      >
        {iconLeft || iconRight ? (
          <Inline as="span" space={1}>
            {iconLeft !== undefined ? (
              <Icon type={iconLeft} color={iconColor} size={iconSize || '24'} />
            ) : null}
            {children}
            {iconRight !== undefined ? (
              <Icon
                type={iconRight}
                color={iconColor}
                size={iconSize || '24'}
              />
            ) : null}
          </Inline>
        ) : (
          children && children
        )}
      </StyledAnchor>
    )
  },
)
