import { Box, Text } from 'ui/core'
import { FeaturedProductCard } from './featuredProductsCard'
import { useEffect, useState } from 'react'
import { Carousel } from 'components/slider/slider'
import { getSliderSettings } from './featuredProductsSliderSettings'
import axios from 'axios'
import { Product } from 'types'
import { FeaturedProductCardSkeleton } from './featuredProductsCardSkeleton'

type Props = {
  title: string
  productIds: string[]
}
export const FeaturedProducts = ({ title, productIds }: Props) => {
  const [products, setProducts] = useState<Product[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const fetchProductsByIds = () => {
    if (productIds && productIds.length > 0) {
      setLoading(true)
      axios
        .get<Product[]>(
          `/umbraco/api/Product/GetProductsJson?itemIds=` +
            productIds.join(','),
        )
        .then((res) => {
          setProducts(!res?.data ? [] : res.data)
          setLoading(false)
        })
        .catch((e) => setLoading(false))
    }
  }

  useEffect(() => {
    fetchProductsByIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box px={8} pt={2}>
      <Text variant="h4" fontWeight="semiBold" color="black" pb={3}>
        {title}
      </Text>
      <Box width="full" ml={3}>
        {loading && (
          <Carousel
            getSliderSettings={(handleNext, handlePrev) =>
              getSliderSettings({}, handleNext, handlePrev)
            }
            items={[...Array(4)]}
            CardComponent={() => (
              <Box height="full" width="full" px={1}>
                <FeaturedProductCardSkeleton />
              </Box>
            )}
          />
        )}
        {!loading && products && (
          <Carousel<Product>
            getSliderSettings={(handleNext, handlePrev) =>
              getSliderSettings({}, handleNext, handlePrev)
            }
            items={products}
            CardComponent={(props:any) => (
              <Box height="full" width="full" px={1}>
                <FeaturedProductCard {...props} />
              </Box>
            )}
          />
        )}
      </Box>
    </Box>
  )
}
