import { Box, ImageBoxWrapper } from 'ui/core';
import styled from 'styled-components';

export const FeaturedProductWrapper = styled.a`
  display: flex;
  height: 100%;
  width: 100%;
  h4 {
    transition: transform 0.3s ease-in-out;
  }
  ${ImageBoxWrapper} {
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    h4 {
      color: ${({ theme }) => theme.colors.primary300};
      transition: transform 0.3s ease-in-out;
    }
    ${ImageBoxWrapper} {
      transform: scale(1.05);
      transition: transform 0.3s ease-in-out;
    }
  }
`;
export const FeaturedProductContentWrapper = styled(Box)`
  max-width: 200px;
`;
