import { FC } from 'react'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { StyledPaginationWrapper } from './pagination.styles'

interface Props {
  pageCount: number
  activePage: number
  onChange: (page: number) => void
}

export const Pagination: FC<Props> = ({ pageCount, activePage, onChange }) => {
  const handlePageChange: ReactPaginateProps['onPageChange'] = (value) => {
    window.scrollTo({
      top: 0,
      left: 0,
    })
    onChange(value.selected + 1)
  }

  if (pageCount <= 1) return null
  return (
    <StyledPaginationWrapper>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={activePage - 1}
        onPageChange={handlePageChange}
        nextLabel="→"
        previousLabel="←"
      />
    </StyledPaginationWrapper>
  )
}
