import styled from 'styled-components'
import { ThemeColorProp } from 'ui/theme'

interface LogoAnchorProps {
  order: number
  color: ThemeColorProp
  hoverColor: ThemeColorProp
}

export const StyledLogoAnchor = styled.a<LogoAnchorProps>`
  display: flex;
  order: ${({ order }) => order};
  background-color: ${({ theme, color }) => theme.colors[color]};
  transition: background-color 300ms;

  &:hover {
    background-color: ${({ theme, hoverColor }) => theme.colors[hoverColor]};
  }
`
