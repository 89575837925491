import styled from 'styled-components'
import { Box } from 'ui/core'
import { media } from 'ui/utils/lib/mediaQuery'

export const HeroBoxPanelTwoWrapper = styled(Box)`
  max-width: 75%;
`
export const HeroWrapper = styled(Box)`
  height: 60vh;
  max-height: 1000px;
  ${media.customUp(2200)} {
    height: 85vh;
  }
  ${media.customUp(1012)} {
    height: 100vh;
  }
`
