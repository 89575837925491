import { Box } from 'ui/core';
import { SkeletonLoader } from 'components/skeletonLoader/skeletonLoader';

export const ProductCardSkeleton = () => (
  <Box
    height="full"
    border="light"
    width="full"
    position="relative"
    pb={[8, 8, 6, 8, 4]}
  >
    <Box
      width="full"
      display="flex"
      position="relative"
      justifyContent="center"
      borderBottom="light"
      pt={2}
      pb={3}
      px={3}
    >
      <Box width="full">
        <SkeletonLoader
          py={[8, 10, 12]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        />
      </Box>
    </Box>
    <Box width="full" position="relative" p={[2, 2, 2, 2, 4]}>
      <SkeletonLoader py={1} mb={1} width="full" />
      <SkeletonLoader py={1} mb={1} width="full" />
      <SkeletonLoader pt={1} mb={4} width="full" />
      <SkeletonLoader py={2} width="full" />
    </Box>
    <Box position="absolute" bottom={3} width="full" px={4}>
      <SkeletonLoader py={2} width="full" />
    </Box>
  </Box>
);
