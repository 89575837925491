import styled from 'styled-components'
import { Anchor } from 'ui/core'

export const StyledAnchor = styled(Anchor)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  > * {
    white-space: nowrap;
    flex-wrap: nowrap;
  }
  svg,
  p {
    transition: all 0.3 ease-in-out;
  }
  &:hover {
    svg,
    p {
      transition: all 0.3 ease-in-out;
      color: ${({ theme }) => theme.colors.primary300};
    }
  }
`
