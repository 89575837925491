import { Text, Box, Container, GridRow, GridColumn, Hidden } from 'ui/core'
import { useEffect, useState } from 'react'
import { useProductSearch } from 'helpers'
import { useDebounce } from 'react-use'
import { Input } from 'ui/core/input/input'
import { ProductCard } from 'components/productCard'
import { Pagination } from 'components/pagination/pagination'
import { InputWrapper } from './search.styles'
import { useUmbracoSearch } from 'helpers/hooks/useUmbracoSearch'
import { SearchQueryResults } from 'components/header/headerSearchInput/searchResults/searchResults'
import { ProductCardSkeleton } from 'components/productCard/productCardSkeleton'

const PAGE_SIZE = 9

export const Search = ({
  title,
  initialQuery,
}: {
  title: string
  initialQuery: string
}) => {
  const debounce = 300
  const [query, setQuery] = useState(initialQuery)
  const [page, setPage] = useState(1)
  const { results, status, value, setValue, setFrom, postSearchResult } =
    useProductSearch(PAGE_SIZE, false)
  const { categories, pages, categoriesLoading, pagesLoading } =
    useUmbracoSearch(value, false)

  const maxPages = results?.count ? Math.ceil(results.count / PAGE_SIZE) : 0

  // Debounced search value by default
  useDebounce(
    () => {
      if (query !== value) setValue(query)
    },
    debounce,
    [query],
  )

  useEffect(() => {
    if (initialQuery) {
      setValue(initialQuery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFrom((page - 1) * PAGE_SIZE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const changePage = (toPage: number) => {
    if (toPage > 0 && toPage <= maxPages) {
      setPage(toPage)
    }
  }

  return (
    <Box
      minHeight="viewportHeight"
      width="full"
      pt={[2, 2, 4, 0]}
      mt={[1, 4, 6]}
    >
      <Container>
        <Box
          px={3}
          pt={4}
          pb={6}
          display="flex"
          justifyContent="space-between"
          flexDirection={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'flex-end']}
        >
          <Box>
            <Text variant="h4" fontWeight="bold" color="dark500" as="h1">
              {title || 'Vöruleit'}{' '}
              <Box as="span" color="dark200">
                {status === 'fulfilled' &&
                  `(${
                    (categories?.count || 0) +
                    (pages?.count || 0) +
                    (results?.count || 0)
                  })`}
              </Box>
            </Text>
            <Hidden below="md">
              <Text color="dark400" variant="textLarge">
                Leitarniðurstöður fyrir{' '}
                <Box as="span" color="primary400">
                  "{query}"
                </Box>
              </Text>
            </Hidden>
          </Box>
          <InputWrapper
            position="relative"
            height="full"
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Input
              icon="search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              name="search-page-input"
            />
          </InputWrapper>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
        >
          <Box width={['full', 'full', 'full', '3/12']} pr={[0, 0, 0, 4]}>
            <SearchQueryResults
              useBorders
              resultsName="Vöruflokkar"
              res={categories?.results || []}
              numResToShow={20}
              loading={categoriesLoading}
              notFoundText={value.length < 3 ? '' : 'Engir vöruflokkar fundust'}
            />
            <Box pt={4}>
              <SearchQueryResults
                useBorders
                loading={pagesLoading}
                resultsName="Efnissíður"
                res={pages?.results || []}
                notFoundText={
                  value.length < 3 ? '' : 'Engar efnissíður fundust'
                }
                numResToShow={20}
              />
            </Box>
          </Box>
          <Box width={['full', 'full', 'full', '9/12']} p={3}>
            <GridRow overflow="visible">
              {status === 'loading' ? (
                <>
                  {[...Array(PAGE_SIZE)].map((_, index) => (
                    <GridColumn
                      key={`loader-ghost-product-${index}`}
                      width={['full', 'full', '1/2', '1/2', '4/12']}
                      pushBottom
                    >
                      <Box width="full" height="full" pb={1}>
                        <ProductCardSkeleton />
                      </Box>
                    </GridColumn>
                  ))}
                </>
              ) : (
                <>
                  {results?.results && results?.results.length > 0 ? (
                    results.results.map((prod) => (
                      <GridColumn
                        key={prod.Sku}
                        width={['full', 'full', '1/2', '1/2', '4/12']}
                        pushBottom
                      >
                        <Box width="full" height="full" pb={1}>
                          <ProductCard
                            {...prod}
                            postSearchResult={postSearchResult}
                          />
                        </Box>
                      </GridColumn>
                    ))
                  ) : (
                    <Box width="full" textAlign="center" p={4}>
                      <Text color="dark200" variant="textLarge" py={1}>
                        {value.length < 3
                          ? 'Leitin er of stutt'
                          : 'Engar vörur fundust fyrir leitarskilyrði'}
                      </Text>
                    </Box>
                  )}
                </>
              )}
            </GridRow>
            {maxPages > 1 && page <= maxPages && (
              <Box pt={4} width="full" display="flex" justifyContent="center">
                <Pagination
                  pageCount={maxPages}
                  onChange={changePage}
                  activePage={page}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
