import { FadeInContent } from 'components/menu/menu.styles'
import { Box, Text } from 'ui/core'
import { Product } from 'types/product'
import { ProductCardAnchor, ProductImage } from './searchProductsResults.styles'
import { formatCurrency } from 'helpers'
import { SkeletonLoader } from 'components/skeletonLoader/skeletonLoader'

export const SearchProductsResults = ({
  resultsName,
  productCount,
  showResults = 5,
  loading,
  res,
  postSearchResult,
}: {
  resultsName: string
  res: Product[]
  productCount?: number
  showResults?: number
  loading: boolean
  postSearchResult: (productId: string) => void
}) => (
  <FadeInContent width="full">
    <Box px={2} pb={2} width="full">
      <Text m={0} fontWeight="bold" variant="textLarge" color="dark500">
        {resultsName}{' '}
        {!loading && productCount && productCount > 0 && (
          <Box as="span" color="dark300">
            {`(${productCount})`}
          </Box>
        )}
      </Text>
    </Box>
    <Box>
      {loading ? (
        <Box px={2}>
          {[...Array(5)].map((_, index) => (
            <Box
              my={2}
              width="full"
              display="flex"
              key={`loading-placeholder-product-${index}`}
            >
              <SkeletonLoader px={6} py={4} mr={2} />
              <Box width="8/12">
                <SkeletonLoader width="full" pt={1} mb={1} />
                <SkeletonLoader width="full" pt={1} mb={2} />
                <SkeletonLoader width="full" py={1} />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          {res.slice(0, showResults).map((res, idx) => (
            <SearchResultProductRow
              postSearchResult={() => postSearchResult(res.Id)}
              key={`search-products-res-${idx}`}
              first={idx < 1}
              {...res}
            />
          ))}
        </Box>
      )}
    </Box>
  </FadeInContent>
)

const SearchResultProductRow = ({
  Title,
  ImageUrl,
  OldPrice,
  CurrencyString,
  Url,
  postSearchResult,
  first,
}: Product & { first?: boolean; postSearchResult: () => void }) => {
  const hasDiscount = OldPrice > 0 && OldPrice > CurrencyString
  return (
    <ProductCardAnchor href={Url} onClick={postSearchResult}>
      <Box
        px={1}
        py={[1, 1, 1, 1, 1, 2]}
        borderTop={first ? 'none' : 'light'}
        display="flex"
        alignItems="center"
        color="inherit"
      >
        <ProductImage mr={4} bg="primary300" image={ImageUrl} />
        <Box width="8/12" color="inherit">
          <Text
            m={0}
            pb={1}
            color="inherit"
            variant="textSmall"
            fontWeight="medium"
          >
            {Title}
          </Text>
          <Box display="flex">
            <Text m={0} variant="text" color="primary300" fontWeight="medium">
              {formatCurrency(CurrencyString)} kr.
            </Text>
          </Box>
          {hasDiscount && (
            <Box
              display="flex"
              color="gray200"
              style={{ textDecoration: 'line-through' }}
            >
              <Text
                m={0}
                variant="textXXSmall"
                color="gray200"
                fontWeight="light"
              >
                {formatCurrency(OldPrice)} kr.
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </ProductCardAnchor>
  )
}
