import { FC } from 'react'
import { Box, Icon } from 'ui/core'
import { Arrow } from './scrollIndicator.styles'

export const ScrollIndicator: FC = () => {
  return (
    <Box
      height="full"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Arrow delay={0}>
        <Icon type="chevronDown" color="primary300" size={'26'} />
      </Arrow>
      <Arrow delay={0.5} pushTop={-15}>
        <Icon type="chevronDown" color="primary300" size={'26'} />
      </Arrow>
      <Arrow delay={0.9} pushTop={-15}>
        <Icon type="chevronDown" color="primary300" size={'26'} />
      </Arrow>
    </Box>
  )
}
