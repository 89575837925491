import styled from 'styled-components'
import { Box } from 'ui/core'

export const IconWrapper = styled(Box)``
export const StyledCard = styled.a`
  width: 100%;
  ${IconWrapper} {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    ${IconWrapper} {
      margin-right: -10px;
      transition: all 0.2s ease-in-out;
    }
  }
`
