import styled, { css } from 'styled-components'
import { media } from 'ui/utils/lib/mediaQuery'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TR = styled.tr`
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: ${({ theme }) => theme.space[2]}px;
  border-radius: ${({ theme }) => theme.radii.small}px;

  ${media.desktopSmallUp} {
    padding: ${({ theme }) => theme.space[2]}px
      ${({ theme }) => theme.space[4]}px;
  }
`

export const Thead = styled.thead`
  display: none;

  ${TR} {
    background-color: ${({ theme }) => theme.colors.primary300};
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: ${({ theme }) => theme.space[2]}px;
  }

  ${media.desktopSmallUp} {
    display: table-header-group;
  }
`

export const Tbody = styled.tbody`
  ${TR} {
    flex-direction: column;

    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.colors.gray100};
    }

    ${media.desktopSmallUp} {
      flex-direction: row;
    }
  }
`

interface CellProps {
  width: number
  ellipsis?: boolean
  usePadding?: boolean
  anchorSize?: 'small' | 'standard'
}

export const TH = styled.th<CellProps>`
  width: ${({ width }) => width}%;
  padding: 0 8px !important;
`

export const TD = styled.td<CellProps>`
  width: 100%;
  ${({ usePadding }) =>
    usePadding &&
    css`
      padding-right: ${({ theme }) => theme.space[3]}px;
    `}
  ${({ anchorSize }) =>
    anchorSize === 'small' &&
    css`
      a {
        font-size: ${({ theme }) => theme.fontSizes['14']}px;
      }
    `}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  label {
    font-weight: 700;
    margin-right: ${({ theme }) => theme.space[1]}px;
  }

  ${media.desktopSmallUp} {
    width: ${({ width }) => width}%;
    padding: 0 8px !important;

    label {
      display: none;
    }
  }
`
