import { useRef } from 'react'
import Slider, { Settings } from 'react-slick'
import { Box, BoxProps } from 'ui/core'
import { Wrapper } from './slider.styles'

type Props<T> = {
  items: T[]
  getSliderSettings: (
    handleNext: () => void,
    handlePrev: () => void,
  ) => Settings
  CardComponent: any
} & BoxProps
export function Carousel<T>(props: Props<T>) {
  const { getSliderSettings, items, CardComponent, ...boxProps } = props
  const slider = useRef<any>(null)
  const handleNext = () => slider?.current?.slickNext()
  const handlePrev = () => slider?.current?.slickPrev()
  const settings = getSliderSettings(handleNext, handlePrev)
  return (
    <Box width="full" zIndex="2">
      <Wrapper {...settings}>
        <Slider {...settings} ref={slider}>
          {items.map((item: T, index) => (
            <Box key={`slider-item-${index}`} width="full">
              <Box {...boxProps}>
                <CardComponent {...item} />
              </Box>
            </Box>
          ))}
        </Slider>
      </Wrapper>
    </Box>
  )
}
