import { MenuNode } from 'types'
import {
  Figure,
  Icon as IconComponent,
  Text,
  ALL_FIGURES,
  ALL_ICONS,
  IconType,
  FigureType,
} from 'ui/core'
import { StyledCategoryBox } from './categoryBox.styles'

export const CategoryBox = ({ item }: { item: MenuNode }) => (
  <a href={item.Url}>
    <StyledCategoryBox
      bg="white"
      border="light"
      height="full"
      width="full"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="small"
      px={2}
    >
      {ALL_FIGURES.includes(item.Icon as FigureType) ? (
        <Figure
          type={
            item.Icon && ALL_FIGURES.includes(item.Icon as FigureType)
              ? (item.Icon as FigureType)
              : 'logoBloma'
          }
          color="gray200"
          fill="gray200"
          size="26"
        />
      ) : ALL_ICONS.includes(item.Icon as IconType) ? (
        <IconComponent type={item.Icon as IconType} color="gray200" size="26" />
      ) : (
        <Figure type="verkfaeri" color="gray200" fill="gray200" size="26" />
      )}
      <Text variant="textSmall" fontWeight="semiBold" m={0} p={0} ml={2}>
        {item.Name}
      </Text>
    </StyledCategoryBox>
  </a>
)
