import styled from 'styled-components'
import { Box } from 'ui/core'

export const StyledCategoryBox = styled(Box)`
  height: 70px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  p {
    color: ${({ theme }) => theme.colors.black};
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    p {
      color: ${({ theme }) => theme.colors.primary300};
      transition: all 0.2s ease-in-out;
    }
    box-shadow: ${({ theme }) => theme.shadows.standard};
    transition: all 0.3s ease-in-out;
  }
`
