import { ThemeProvider } from 'styled-components'
import { getTheme } from '../ui/theme'
import { Box, Text } from 'ui/core'
import { Staff, ThemeVariant } from 'types'
import { Table } from 'components'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Input } from 'ui/core/input/input'
import { Dropdown } from 'ui/core/dropdown/dropdown'

interface Props {
  site: ThemeVariant
}
export const StaffTableApp = ({ site }: Props) => {
  const theme = getTheme(site)
  const [staffResult, setStaffResult] = useState<string[][]>([])
  const [loading, setLoading] = useState<boolean>()
  const [queryValue, setQueryValue] = useState<string>('')
  const [departmentValue, setDepartmentValue] = useState<string>('')
  const [departmentOptions, setDepartmentOptions] = useState<
    { title: string; id: string }[]
  >([])

  // Get all available department filters based on data
  const getDepartmentFilters = () => {
    axios
      .get<{ Name: string }[]>(`/umbraco/api/Staff/GetDepartmentOptions`)
      .then((res) => {
        setDepartmentOptions(
          !res?.data
            ? []
            : res.data.map((r) => ({ title: r.Name, id: r.Name })),
        )
      })
  }

  // Reset staff table view,
  // That is, re-fetch data with given filters if set
  const resetView = () => {
    if (queryValue.length < 1 && !departmentValue) {
      setStaffResult([])
      return
    }
    setLoading(true)
    axios
      .get<Staff[]>(
        `/umbraco/api/Staff/GetStaff?${
          queryValue.length > 1 ? `search=${encodeURI(queryValue)}` : ''
        }${queryValue.length > 1 && departmentValue ? '&' : ''}${
          departmentValue ? `department=${encodeURI(departmentValue)}` : ''
        }`,
      )
      .then((res) => {
        setStaffResult(
          !res?.data
            ? []
            : res.data.map(({ Name, Title, Department, Phone, Email }) => [
                Name,
                Title,
                Phone,
                Email,
                Department,
              ]),
        )
        setLoading(false)
      })
      .catch((e) => setLoading(false))
  }

  // Initialize data and filters
  useEffect(() => {
    getDepartmentFilters()
    resetView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Re-fetch and refresh table when user search table or filters by department
  useEffect(() => {
    resetView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, departmentValue])

  return (
    <ThemeProvider theme={theme}>
      <Box width="full" py={4}>
        <Box
          display="flex"
          justifyContent="space-between"
          width="full"
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
          pt={6}
        >
          <Text mx={1} variant="h4" fontWeight="semiBold" color="black">
            Leita að starfsfólki
          </Text>
          <Box
            py={2}
            px={[1, 1, 0]}
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-end"
            width={['full', 'full', 'full', '1/2']}
            height="full"
          >
            <Box width={['full', 'full', '1/2', '7/12', '6/12']}>
              <Dropdown
                selectedItem={departmentValue}
                items={departmentOptions}
                placeholder="Leita eftir deild"
                onSelectionChanged={(id) => setDepartmentValue(id)}
                onSelectionCleared={() => setDepartmentValue('')}
              />
            </Box>
            <Box
              width={['full', 'full', '1/2', '5/12', '6/12']}
              pl={[0, 0, 2]}
              pt={[1, 1, 0]}
            >
              <Input
                placeholder="Leita..."
                name="staff-list-search"
                value={queryValue}
                onChange={(e) => setQueryValue(e.target.value || '')}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" pt={2} pb={20}>
          {loading && (
            <Text py={2} mx={1} fontWeight="medium" color="gray150">
              Sæki...
            </Text>
          )}
          {!loading && queryValue.length > 1 && staffResult.length < 1 && (
            <Text py={2} mx={1} fontWeight="medium" color="gray150">
              Ekkert fannst fyrir þessi leitarskilyrði
            </Text>
          )}
          {!loading && staffResult.length > 0 && (
            <Table
              head={['Nafn', 'Titill', 'Sími', 'Netfang', 'Deild']}
              data={staffResult}
              loading={loading}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}
