import styled from 'styled-components';
import { Box } from 'ui/core';

export const ImageBoxWrapper = styled(Box)`
  height: 192px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
