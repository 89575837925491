import { ButtonSize, ButtonVariant, ThemeFlexboxProp } from 'ui/theme'
import React, { forwardRef } from 'react'
import flattenChildren from 'react-keyed-flatten-children'
import { Box, BoxResponsiveMarginProps } from '../box/box'
import { ButtonWidth, StyledButton } from './button.styles'
import { Icon, IconType } from '../icon/icon'

export type ButtonBaseProps = Omit<
  BoxResponsiveMarginProps &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
  'ref'
> & {
  disabled?: boolean
  width?: ButtonWidth
  justifyContent?: ThemeFlexboxProp
  isLoading?: boolean
  iconLeft?: IconType
  iconRight?: IconType
  variant?: ButtonVariant
  size?: ButtonSize
}

export const Button = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  (
    {
      variant = 'primary',
      size = 'large',
      iconLeft,
      iconRight,
      children,
      ...props
    },
    forwardedRef,
  ) => {
    const flattenedChildren = flattenChildren(children)

    return (
      <StyledButton variant={variant} size={size} ref={forwardedRef} {...props}>
        {iconLeft && (
          <Box
            display="flex"
            alignItems="center"
            as="span"
            mr={flattenedChildren && flattenedChildren.length > 0 ? 1 : 0}
          >
            <Icon type={iconLeft} />
          </Box>
        )}
        {flattenedChildren}
        {iconRight && (
          <Box
            display="flex"
            alignItems="center"
            as="span"
            ml={flattenedChildren && flattenedChildren.length > 0 ? 1 : 0}
          >
            <Icon type={iconRight} />
          </Box>
        )}
      </StyledButton>
    )
  },
)
