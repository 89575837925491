import {
  FadeInContent,
  MenuHoverAnchor,
  MenuItemHoverAnchor,
  ScollIndicatorWrapper,
  ScrollBg,
  ScrollerBtn,
} from 'components/menu/menu.styles'
import { ScrollIndicator } from 'components/scrollIndicator/scrollIndicator'
import { FC, MouseEvent as MEvent, RefObject } from 'react'
import { MenuNode } from 'types'
import { Box, GridColumn, GridRow, Text } from 'ui/core'

interface Props {
  menu: MenuNode
  hasScrolled: boolean
  menuRef: RefObject<HTMLDivElement | null>
}

export const ContentMenuItem: FC<Props> = ({ menu, menuRef, hasScrolled }) => {
  // Partitions menu in matrix of n-th sized arrays
  const partitionMenu = (input: MenuNode[], n: number) => {
    var output = []
    for (var i = 0; i < input.length; i += n) {
      output[output.length] = input.slice(i, i + n)
    }
    return output
  }
  const menus = !menu || !menu.Children ? [] : partitionMenu(menu.Children, 6)

  const handleScrollClick = (e: MEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation()
    if (menuRef && menuRef?.current) {
      menuRef.current.scrollTo({
        top: menuRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <FadeInContent
      width="full"
      height="viewportHeight"
      px={[2, 2, 2, 2, 4]}
      py={6}
    >
      {menu &&
        menus &&
        menus.length > 0 &&
        menus.map((segment, idx) => (
          <GridRow key={'content-menu-row-' + idx}>
            {segment.map(({ Name, Url, Children }, idx) => (
              <GridColumn key={idx} width={['full', 'full', '2/12']} pushBottom>
                <Box
                  key={idx}
                  width="full"
                  display="flex"
                  flexDirection="column"
                >
                  <Box pl={1}>
                    <MenuHoverAnchor href={Url}>
                      <Text
                        variant="textSmall"
                        color="primary300"
                        fontWeight="bold"
                        pb={1}
                      >
                        {Name}
                      </Text>
                    </MenuHoverAnchor>
                    {Children &&
                      Children.map(({ Name, Url }, idx) => (
                        <MenuItemHoverAnchor href={Url} key={idx}>
                          <Text
                            color="dark500"
                            variant="textSmall"
                            fontWeight="regular"
                          >
                            {Name}
                          </Text>
                        </MenuItemHoverAnchor>
                      ))}
                  </Box>
                </Box>
              </GridColumn>
            ))}
          </GridRow>
        ))}
      <ScollIndicatorWrapper
        position="absolute"
        visible={hasScrolled}
        top={0}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        height="viewportHeight"
        width="full"
      >
        <ScrollBg
          height="5/10"
          width="full"
          display="flex"
          justifyContent="center"
        >
          <ScrollerBtn p={4} onClick={(e) => handleScrollClick(e)}>
            <ScrollIndicator />
          </ScrollerBtn>
        </ScrollBg>
      </ScollIndicatorWrapper>
    </FadeInContent>
  )
}
