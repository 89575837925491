import styled from 'styled-components';
import { ImageBoxWrapper } from 'ui/core';

export const CardLink = styled.a`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.dark400};
  ${ImageBoxWrapper} {
    img {
      transform: scale(1);
      transition: transform 0.3s ease-in-out;
    }
  }
  &:hover {
    ${ImageBoxWrapper} {
      img {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
      }
    }
    color: ${({ theme }) => theme.colors.primary400};
    transition: transform 0.3s ease-in-out;
  }
`;
