import styled from 'styled-components'
import { media } from 'ui/utils/lib/mediaQuery'

interface Props {
  boxSize?: 'small' | 'large'
  noFocusBorder?: boolean
}
export const SearchInput = styled.input<Props>`
  border: none;
  outline: none;
  flex-grow: 1;
  width: 100%;
  padding: ${({ theme, boxSize }) =>
    theme.space[boxSize === 'large' ? 2 : 1]}px;
  font-size: ${({ theme, boxSize }) =>
    theme.fontSizes[boxSize === 'large' ? 18 : 12]}px;
  font-weight: ${({ theme, boxSize }) =>
    boxSize === 'large' ? theme.fontWeights.medium : theme.fontWeights.regular};
  transition: box-shadow 300ms;
  color: ${({ theme }) => theme.colors.dark400};
  font-weight: 400;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark200};
  transition: border-bottom 300ms;
  &::placeholder {
    color: ${({ theme }) => theme.colors.dark400};
  }
  &:focus {
    border-bottom: 1px solid
      ${({ noFocusBorder, theme }) =>
        noFocusBorder ? theme.colors.transparent : theme.colors.primary300};
    transition: border-bottom 300ms;
  }
  &::-webkit-search-cancel-button {
    display: none;
  }
  ${media.desktopSmallUp} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.transparent};
    font-size: ${({ theme, boxSize }) =>
      theme.fontSizes[boxSize === 'large' ? 34 : 18]}px;
    padding: ${({ theme }) => theme.space[2]}px
      ${({ theme, boxSize }) => theme.space[boxSize === 'large' ? 6 : 2]}px;
    padding-bottom: 2px;
  }
`
