import { ImageBoxWrapper } from './imageBox.styles';

type Props = {
	imgSrc: string;
};
export const ImageBox = ({ imgSrc }: Props) => (
	<ImageBoxWrapper>
		<img src={imgSrc} alt="" />
	</ImageBoxWrapper>
);
