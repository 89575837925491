import { Settings } from 'react-slick'
import { Box, Icon } from 'ui/core'

const Arrow = ({
  iconVariant = 'arrowRight',
  onClick,
}: {
  onClick: () => void
  iconVariant?: 'arrowLeft' | 'arrowRight'
}) => (
  <Box
    position="absolute"
    zIndex="3"
    top={0}
    bottom={0}
    left={iconVariant === 'arrowLeft' ? -50 : 'inherit'}
    right={iconVariant === 'arrowRight' ? -50 : 'inherit'}
    role="button"
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    p={3}
  >
    <Icon type={iconVariant} color="primary300" size="32" />
  </Box>
)

export const getSliderSettings = (
  settings: Settings,
  handleNext: () => void,
  handlePrev: () => void,
): Settings => ({
  ...settings,
  arrows: true,
  nextArrow: <Arrow onClick={handleNext} iconVariant="arrowRight" />,
  prevArrow: <Arrow onClick={handlePrev} iconVariant="arrowLeft" />,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  touchMove: true,
  swipe: true,
  accessibility: false,
  swipeToSlide: true,
  infinite: true,
  dots: false,
  autoplay: false,
  draggable: true,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1362,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 778,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
})
