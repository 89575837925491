import axios from 'axios'
import { useEffect, useState } from 'react'
import { SearchResultsResponse } from 'types'

const SearchRouteCategoriesBaseUrl = '/Umbraco/surface/search/SearchCategories'
const SearchRouteContentPagesBaseUrl = '/Umbraco/surface/search/SearchContent'

const MIN_LENGTH = 3

// Searches Umbraco for search term and returns allatching values
// Pages are either content pages or product categories
// No pagination provided here by design due to Umbraco search limitations
export const useUmbracoSearch = (term = '', searchOnInit = true) => {
  const [pages, setPages] = useState<SearchResultsResponse | null>(null)
  const [categories, setCategories] = useState<SearchResultsResponse | null>(
    null,
  )
  const [pagesLoading, setPagesLoading] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState(false)

  // Search both categories and pages by search term
  const searchUmbraco = async () => {
    if (!term || term.length < MIN_LENGTH) {
      setPages(null)
      setCategories(null)
      return
    }
    searchCategories()
    searchPages()
  }

  // Search umbraco for product categories
  const searchCategories = async () => {
    setCategoriesLoading(true)
    const categoriesRes = await axios.get<SearchResultsResponse>(
      `${SearchRouteCategoriesBaseUrl}?searchTerm=${term}`,
    )
    if (categoriesRes && categoriesRes.data) {
      setCategories(categoriesRes.data)
      setCategoriesLoading(false)
    } else {
      setCategories(null)
      setCategoriesLoading(false)
    }
  }

  // Search umbraco for content pages
  const searchPages = async () => {
    setPagesLoading(true)
    const pagesRes = await axios.get<SearchResultsResponse>(
      `${SearchRouteContentPagesBaseUrl}?searchTerm=${term}`,
    )
    if (pagesRes && pagesRes.data) {
      setPages(pagesRes.data)
      setPagesLoading(false)
    } else {
      setPages(null)
      setPagesLoading(false)
    }
  }

  useEffect(() => {
    searchUmbraco()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term])

  useEffect(() => {
    if (searchOnInit && term && term.length >= MIN_LENGTH) {
      searchUmbraco()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    searchUmbraco,
    pagesLoading,
    categoriesLoading,
    pages,
    categories,
  }
}
