import { Box, ImageBox, Text } from 'ui/core';
import {
	FeaturedProductContentWrapper,
	FeaturedProductWrapper,
} from './featuredProductsCard.styles';
import { Product } from 'types';
import { formatCurrency } from 'helpers';

export const FeaturedProductCard = ({
	Title,
	CurrencyString,
	OldPrice,
	ImageUrl,
	Url,
}: Product) => (
	<FeaturedProductWrapper href={Url}>
		<Box p={2} height="full" border="light">
			<Box px={7} py={8} width="full" height="full">
				<ImageBox imgSrc={ImageUrl} />
			</Box>
		</Box>
		<FeaturedProductContentWrapper pl={3}>
			<Text
				variant="textSmall"
				as="h4"
				fontWeight="semiBold"
				color="black"
			>
				{Title}
			</Text>
			<Text
				variant="textIntro"
				pt={2}
				pb={1}
				m={0}
				fontWeight="semiBold"
				color="primary300"
			>
				{formatCurrency(CurrencyString)} kr.
			</Text>
			{!!OldPrice && (
				<Box color="dark400" style={{ textDecoration: 'line-through' }}>
					<Text variant="textSmall" color="dark400">
						{formatCurrency(OldPrice)} kr.
					</Text>
				</Box>
			)}
		</FeaturedProductContentWrapper>
	</FeaturedProductWrapper>
);
