import { FC } from 'react'
import { ThemeVariant } from 'types'
import { AnchorProps, Box, Hidden, Text } from 'ui/core'
import { ThemeBreakpointNames } from 'ui/theme'
import { StyledAnchor } from './headerControls.styles'
import { UserDropdown } from '../../userDropdown/userDropdown'
import { Account } from 'types/account'

export const HeaderControls: FC<{
  toggleContentMenu: () => void
  contentMenuOpen?: boolean
  toggleUserMenu: () => void
  userMenuOpen?: boolean
  userName?: string
  theme: ThemeVariant
  isLoggedIn: boolean
  accounts: Account[] | null
  accountsLoading: boolean
}> = ({
  toggleContentMenu,
  toggleUserMenu,
  contentMenuOpen,
  userMenuOpen,
  userName,
  theme,
  isLoggedIn,
  accounts,
  accountsLoading,
}) => (
  <Hidden below="md">
    <Box
      display="flex"
      flexGrow={1}
      alignItems={'center'}
      justifyContent="flex-end"
      flexWrap="nowrap"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="full"
        ml={[0, 0, 2]}
        mr={[1, 1, 3, 3, 4, 5]}
      >
        <HeaderButton
          onClick={toggleContentMenu}
          iconLeft="info"
          iconColor={contentMenuOpen ? 'primary300' : 'dark300'}
          hiddenBelow={isLoggedIn && userName ? 'xl' : 'lg'}
        >
          Upplýsingar
        </HeaderButton>
      </Box>
      <Box
        display={['none', 'flex']}
        justifyContent="center"
        alignItems="center"
        height="full"
        mr={[1, 1, 3, 3, 4, 5]}
      >
        <HeaderButton
          href={
            theme === ThemeVariant.BLOMA
              ? '/blomaval/verslanir/'
              : '/upplysingar/afgreidslutimi/'
          }
          iconLeft="time"
          iconColor="dark300"
          hiddenBelow="xl"
        >
          Afgreiðslutímar
        </HeaderButton>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="full"
        mr={[1, 1, 3, 3, 4, 5]}
      >
        {userName && isLoggedIn ? (
          <>
            <Box position={['static', 'static', 'static', 'relative']}>
              <HeaderButton
                onClick={toggleUserMenu}
                iconLeft="user"
                iconColor={userMenuOpen ? 'primary300' : 'dark300'}
                hiddenBelow="lg"
              >
                {userName || 'Innskráning'}
              </HeaderButton>
              <Hidden below="md">
                <UserDropdown
                  accounts={accounts}
                  accountsLoading={accountsLoading}
                  isOpen={userMenuOpen}
                  theme={theme}
                  userName={userName || ''}
                />
              </Hidden>
            </Box>
            <Hidden below="lg">
              <HeaderButton
                iconLeft="chevronDown"
                iconColor={userMenuOpen ? 'primary300' : 'dark300'}
              />
            </Hidden>
          </>
        ) : (
          <HeaderButton
            href="https://vidskiptavefur.husa.is/enterhusa"
            iconLeft="user"
            iconColor="dark300"
            hiddenBelow="xl"
          >
            Innskráning
          </HeaderButton>
        )}
      </Box>
    </Box>
  </Hidden>
)

export const HeaderMobileControls: FC<{
  isLoggedIn: boolean
  userName?: string
  toggleUserMenu: () => void
  toggleContentMenu: () => void
  theme: ThemeVariant
}> = ({ toggleContentMenu, toggleUserMenu, isLoggedIn, theme, userName }) => (
  <>
    <Box
      width="6/12"
      borderLeft="light"
      display="flex"
      justifyContent="center"
      py={2}
    >
      <StyledAnchor
        onClick={toggleContentMenu}
        iconColor="primary300"
        variant="dark"
        iconLeft="info"
      >
        Upplýsingar
      </StyledAnchor>
    </Box>
    <Box
      width="6/12"
      borderLeft="light"
      display="flex"
      justifyContent="center"
      py={2}
    >
      {isLoggedIn ? (
        <>
          <Hidden width="full" below="sm">
            <StyledAnchor
              iconLeft="user"
              iconColor="primary300"
              variant="dark"
              onClick={toggleUserMenu}
            >
              {userName || 'Innskráning'}
            </StyledAnchor>
          </Hidden>
          <Hidden width="full" above="sm">
            <StyledAnchor
              iconLeft="user"
              iconColor="primary300"
              variant="dark"
              onClick={toggleUserMenu}
            >
              {userName && userName.includes(' ')
                ? userName.split(' ').slice(0, 2).join(' ').length <= 20
                  ? userName.split(' ').slice(0, 2).join(' ')
                  : userName.split(' ')[0]
                : userName || 'Innskráning'}
            </StyledAnchor>
          </Hidden>
        </>
      ) : (
        <StyledAnchor
          href="https://vidskiptavefur.husa.is/enterhusa"
          iconLeft="user"
          iconColor="primary300"
          variant="dark"
        >
          Innskráning
        </StyledAnchor>
      )}
    </Box>
  </>
)

interface HeaderButtonProps {
  children?: string
  hiddenBelow?: Exclude<ThemeBreakpointNames, 'xs'>
}
export const HeaderButton: FC<HeaderButtonProps & AnchorProps> = ({
  hiddenBelow,
  children,
  ...anchorProps
}) =>
  hiddenBelow ? (
    <StyledAnchor variant="standard-regular" {...anchorProps}>
      {children && (
        <Hidden below={hiddenBelow} mx={0}>
          <HeaderButtonInner {...anchorProps}>{children}</HeaderButtonInner>
        </Hidden>
      )}
    </StyledAnchor>
  ) : (
    <StyledAnchor variant="standard-regular" {...anchorProps}>
      {children && (
        <HeaderButtonInner {...anchorProps}>{children}</HeaderButtonInner>
      )}
    </StyledAnchor>
  )

const HeaderButtonInner: FC<{ children: string }> = ({ children }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexWrap="nowrap"
    width="full"
  >
    <Box
      display="flex"
      width="full"
      height="full"
      justifyContent="center"
      alignItems="center"
    >
      <Text color="primary300" p={0} m={0}>
        {children}
      </Text>
    </Box>
  </Box>
)
