import { MenuNode } from 'types/megamenu'

export const provideHeaderData = () => {
  const HEADER_DATA = (window as any).__HEADER_DATA
  const {
    theme,
    basketCount,
    wishlistCount,
    loggedIn,
    userName,
    nav,
    contentNav,
    contentPromoImage,
  } = HEADER_DATA
  var navParsed: MenuNode[] = []
  var contentNavParsed: MenuNode[] = []
  try {
    navParsed = JSON.parse(nav)
    contentNavParsed = JSON.parse(contentNav)
  } catch (e) {
    // Invalid json
    console.log(e)
  }
  const contentChildNavs = contentNavParsed
    .map((x) => x?.Children || [])
    .filter((x) => x !== undefined)
  return {
    theme,
    basketCount,
    userName,
    wishlistCount,
    nav: navParsed,
    loggedIn: loggedIn ? true : false,
    contentNav: {
      Name: 'Upplýsingar',
      Children: Array.prototype.concat.apply([], contentChildNavs),
    },
    contentPromoImage: contentPromoImage,
  }
}
