import { MobileMenuContainer } from 'components/menu/mobileMenu/mobileMenu.styles'
import { MobileMenuScreen } from 'components/menu/mobileMenu/mobileMenuScreen'
import { FC, useState } from 'react'
import { MenuNode, ThemeVariant } from 'types'
import { Box } from 'ui/core'

interface Props {
  menu: MenuNode
  theme: ThemeVariant
  goBack: () => void
}

export const MobileContentMenu: FC<Props> = ({ menu, theme, goBack }) => {
  const [activeCategory, setActiveCategory] = useState<MenuNode | null>(null)
  return (
    <Box
      height="viewportHeight"
      overflowY="scroll"
      overflowX="hidden"
      width="full"
      display="flex"
      flexWrap="nowrap"
      justifyContent="flex-start"
      alignItems="stretch"
      position="relative"
      pt={[6, 6, 8]}
    >
      {/* 2nd level menu */}
      <MobileMenuContainer
        bg="white"
        active={menu !== null}
        width="full"
        height="full"
        position="absolute"
        zIndex="2"
      >
        {menu && (
          <MobileMenuScreen
            items={menu.Children || []}
            itemActive={activeCategory?.Name}
            parentCategory={menu.Name}
            parentUrl={menu.Url}
            goBack={goBack}
            onClick={(item) => setActiveCategory(item)}
          />
        )}
      </MobileMenuContainer>
      {/* 3rd level menu */}
      <MobileMenuContainer
        bg="white"
        active={activeCategory !== null}
        width="full"
        height="full"
        position="absolute"
        zIndex="3"
      >
        {activeCategory && (
          <MobileMenuScreen
            items={activeCategory.Children || []}
            goBack={() => setActiveCategory(null)}
            parentCategory={activeCategory.Name}
            parentUrl={activeCategory.Url}
          />
        )}
      </MobileMenuContainer>
    </Box>
  )
}
