import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import {
  HeaderApp,
  FooterApp,
  CategorySliderApp,
  StaffTableApp,
  RentDevicesCategoriesApp,
  RentDevicesApp,
  SimplyBookApp,
} from 'apps'
import { ThemeVariant } from 'types'
import { SearchApp } from 'apps/SearchApp'
import { FrontpageDuoHeroApp } from 'apps/frontpageDuoHeroApp'

const renderHeader = () => {
  const entry = document.getElementById('header-app-entry')
  if (entry) {
    ReactDOM.render(
      <React.StrictMode>
        <HeaderApp />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderFooter = () => {
  const entry = document.getElementById('react-footer-entry')
  if (entry) {
    ReactDOM.render(
      <React.StrictMode>
        <FooterApp />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderRentDevicesCategories = () => {
  const entry = document.getElementById('react-rent-devices-categories-entry')
  if (entry) {
    const umbracoCategoriesRaw = entry.getAttribute('data-umbraco-categories')
    const ancestorsRaw = entry.getAttribute('data-ancestors')
    const site = entry.getAttribute('data-site-theme')
    const title = entry.getAttribute('data-title')
    var umbracoCategories = []
    var ancestors = []
    try {
      ancestors = ancestorsRaw ? JSON.parse(ancestorsRaw) : []
      umbracoCategories = umbracoCategoriesRaw
        ? JSON.parse(umbracoCategoriesRaw)
        : []
    } catch (e) {
      // Invalid json
      console.log(e)
    }
    ReactDOM.render(
      <React.StrictMode>
        <RentDevicesCategoriesApp
          umbracoCategories={umbracoCategories}
          title={title || ''}
          ancestors={ancestors || []}
          site={site ? (site as ThemeVariant) : ThemeVariant.HUSA}
        />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderRentDevices = () => {
  const entry = document.getElementById('react-rent-devices-entry')
  if (entry) {
    const categoryId = entry.getAttribute('data-category-id')
    const categoryName = entry.getAttribute('data-category-name')
    const site = entry.getAttribute('data-site-theme')
    const title = entry.getAttribute('data-title')
    const ancestorsRaw = entry.getAttribute('data-ancestors')
    var ancestors = []
    try {
      ancestors = ancestorsRaw ? JSON.parse(ancestorsRaw) : []
    } catch (e) {
      // Invalid json
      console.log(e)
    }
    ReactDOM.render(
      <React.StrictMode>
        <RentDevicesApp
          title={title || ''}
          categoryId={categoryId}
          categoryName={categoryName}
          ancestors={ancestors || []}
          site={site ? (site as ThemeVariant) : ThemeVariant.HUSA}
        />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderSearchApp = () => {
  const entry = document.getElementById('react-search-entry')
  if (entry) {
    const site = entry.getAttribute('data-site-theme')
    const title = entry.getAttribute('data-title')
    const initialQuery = entry.getAttribute('data-query')
    ReactDOM.render(
      <React.StrictMode>
        <SearchApp
          title={title || ''}
          initialQuery={initialQuery || ''}
          site={site ? (site as ThemeVariant) : ThemeVariant.HUSA}
        />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderCategorySlider = () => {
  const entry = document.getElementById('react-category-slider-entry')
  if (entry) {
    ReactDOM.render(
      <React.StrictMode>
        <CategorySliderApp />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderStaffList = () => {
  const entry = document.getElementById('react-staff-table-entry')
  if (entry) {
    ReactDOM.render(
      <React.StrictMode>
        <StaffTableApp site={(entry.dataset.theme as ThemeVariant) || 'husa'} />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderFrontpageDuoHero = () => {
  const entry = document.getElementById('react-frontpage-duo-hero-entry')
  if (entry) {
    const site = entry.getAttribute('data-site-theme')
    const panelOneTitle = entry.getAttribute('data-panelOneTitle')
    const panelOneText = entry.getAttribute('data-panelOneText')
    const panelOneImage = entry.getAttribute('data-panelOneImage')
    const panelOneButtonText = entry.getAttribute('data-panelOneButtonText')
    const panelOneButtonLink = entry.getAttribute('data-panelOneButtonLink')
    const panelTwoTitle = entry.getAttribute('data-panelTwoTitle')
    const panelTwoText = entry.getAttribute('data-panelTwoText')
    const panelTwoImage = entry.getAttribute('data-panelTwoImage')
    const panelTwoButtonText = entry.getAttribute('data-panelTwoButtonText')
    const panelTwoButtonLink = entry.getAttribute('data-panelTwoButtonLink')
    const featuredProductsTitle = entry.getAttribute(
      'data-featuredProductsTitle',
    )
    const featuredProductsIds = entry.getAttribute('data-featuredProductsIds')
    ReactDOM.render(
      <React.StrictMode>
        <FrontpageDuoHeroApp
          site={site ? (site as ThemeVariant) : ThemeVariant.HUSA}
          panelOneTitle={panelOneTitle || ''}
          panelOneText={panelOneText || ''}
          panelOneImage={panelOneImage || ''}
          panelOneButtonText={panelOneButtonText || ''}
          panelOneButtonLink={panelOneButtonLink || ''}
          panelTwoTitle={panelTwoTitle || ''}
          panelTwoText={panelTwoText || ''}
          panelTwoImage={panelTwoImage || ''}
          panelTwoButtonText={panelTwoButtonText || ''}
          panelTwoButtonLink={panelTwoButtonLink || ''}
          featuredProductsTitle={featuredProductsTitle || ''}
          featuredProductsIds={featuredProductsIds || ''}
        />
      </React.StrictMode>,
      entry,
    )
  }
}

const renderSimplyBook = () => {
  const entry = document.getElementById('react-simplybook')
  if (entry) {
    const site = entry.getAttribute('data-site-theme')
    ReactDOM.render(
      <React.StrictMode>
        <SimplyBookApp
          site={site ? (site as ThemeVariant) : ThemeVariant.HUSA}
        />
      </React.StrictMode>,
      entry,
    )
  }
}

// Inject all components to the dom tree
const renderComponents = () => {
  renderHeader()
  renderFooter()
  renderRentDevicesCategories()
  renderRentDevices()
  renderCategorySlider()
  renderStaffList()
  renderSimplyBook()
  renderSearchApp()
  renderFrontpageDuoHero()
}

renderComponents()
reportWebVitals()
