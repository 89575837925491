import styled, { keyframes } from 'styled-components'
import { Box } from 'ui/core'

export const AvailabilityIndicator = styled(Box)<{ available?: boolean }>`
  background-color: ${({ theme, available }) =>
    !available ? theme.colors.husa : theme.colors.bloma};
  width: 8px;
  height: 8px;
`

export const ImageWrapper = styled(Box)``

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const AvailabilityModalWrapper = styled(Box)<{ show?: boolean }>`
  animation: ${appear} 0.3s linear forwards;
  background: #0000006b;
`
