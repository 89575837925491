import { Box } from 'ui/core'
import { FeaturedProductContentWrapper } from './featuredProductsCard.styles'
import { SkeletonLoader } from 'components'

export const FeaturedProductCardSkeleton = () => (
  <Box display="flex" width="full" height="full">
    <Box p={2} height="full" border="light">
      <SkeletonLoader px={7} py={7} width="full" height="full" />
    </Box>
    <FeaturedProductContentWrapper pl={3} width="full">
      <SkeletonLoader py={1} mb={1} width="full" />
      <SkeletonLoader py={1} width="full" />
      <SkeletonLoader mt={3} py={2} width="full" />
      <SkeletonLoader mt={1} py={1} width="full" />
    </FeaturedProductContentWrapper>
  </Box>
)
