import { Header as HeaderType, ThemeVariant } from 'types'
import { StyledHeader } from './header.styles'
import { Box, Hidden } from 'ui/core'
import { HeaderControls } from './headerControls/headerControls'
import { HeaderSearchInput } from './headerSearchInput/headerSearchInput'
import { HeaderListButtons } from './headerListButtons/headerListButtons'
import { HeaderMainLinks } from './headerMainLinks/headerMainLinks'
import { Hamburger } from 'ui/core/hamburger/hamburger'
import { Menu } from 'components/menu/menu'
import { ContentMenu } from 'components/contentMenu/contentMenu'
import { Account } from 'types/account'
import { MobileUserMenu } from 'components/userDropdown/mobileUserMenu'

interface Props {
  header: HeaderType
  theme: ThemeVariant
  userName: string
  isLoggedIn: boolean
  toggleMenu: () => void
  toggleUserMenu: () => void
  toggleContentMenu: () => void
  menuOpen: boolean
  contentMenuOpen: boolean
  userMenuOpen: boolean
  accounts: Account[] | null
  accountsLoading: boolean
  setMenuState: (isOpen: boolean) => void
}

export const HeaderComponent = ({
  header,
  userName,
  theme,
  isLoggedIn,
  toggleMenu,
  toggleContentMenu,
  toggleUserMenu,
  accounts,
  menuOpen,
  contentMenuOpen,
  userMenuOpen,
  accountsLoading,
}: Props) => (
  <StyledHeader>
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="stretch"
      width="full"
      bg="white"
    >
      <Box order={1}>
        <HeaderMainLinks theme={header.theme} />
      </Box>
      <Box
        height="full"
        position="relative"
        order={[5, 5, 2]}
        px={[0, 0, 2, 3]}
      >
        <Hamburger onClick={toggleMenu} isOpen={menuOpen} />
      </Box>
      <Box
        order={[2, 2, 3]}
        borderLeft={['none', 'none', 'light']}
        height="full"
        width={['auto', 'auto', 'full']}
      >
        <HeaderSearchInput />
      </Box>
      <Box
        display="flex"
        justifyContent={['flex-start', 'flex-end']}
        alignItems="stretch"
        width={['full', 'full', 'auto']}
        order={[3, 3, 4]}
      >
        <HeaderControls
          theme={theme}
          userName={userName}
          accounts={accounts}
          isLoggedIn={isLoggedIn}
          toggleContentMenu={toggleContentMenu}
          toggleUserMenu={toggleUserMenu}
          contentMenuOpen={contentMenuOpen}
          userMenuOpen={userMenuOpen}
          accountsLoading={accountsLoading}
        />
        <HeaderListButtons
          basketCount={header.basketCount}
          wishlistCount={header.wishlistCount}
          theme={header.theme}
          isLoggedIn={isLoggedIn}
          userName={userName}
        />
      </Box>
    </Box>
    <Menu
      nav={header.nav}
      open={menuOpen}
      theme={header.theme}
      toggleContentMenu={toggleContentMenu}
      toggleUserMenu={toggleUserMenu}
      isLoggedIn={isLoggedIn}
      userName={userName}
    />
    <ContentMenu
      theme={header.theme}
      nav={header.contentNav}
      open={contentMenuOpen}
      promoImage={header.contentPromoImage}
      goBack={toggleMenu}
    />
    <Hidden above="md">
      <MobileUserMenu
        userName={userName}
        theme={header.theme}
        accounts={accounts}
        open={userMenuOpen}
        accountsLoading={accountsLoading}
      />
    </Hidden>
  </StyledHeader>
)
