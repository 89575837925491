import { FrontpageDuoHero, FrontpageDuoHeroProps } from 'components'
import { ThemeProvider } from 'styled-components'
import { ThemeVariant } from 'types'
import { Box } from 'ui/core'
import { getTheme } from 'ui/theme'

export const FrontpageDuoHeroApp = ({
  site,
  ...heroProps
}: { site: ThemeVariant } & FrontpageDuoHeroProps) => {
  const theme = getTheme(site)
  return (
    <ThemeProvider theme={theme}>
      <Box width="full" overflow="hidden">
        <FrontpageDuoHero {...heroProps} />
      </Box>
    </ThemeProvider>
  )
}
