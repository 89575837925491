import styled from 'styled-components'
import { Box } from 'ui/core'
import { media } from 'ui/utils/lib/mediaQuery'

export const SearchIconWrapper = styled(Box)`
  position: absolute;
  right: 40px;
  top: 25%;
  margin-left: 20px;
  transition: color 300ms;
  ${media.mobile} {
    right: 5px;
    top: 20%;
    margin-left: 10px;
  }
`
