import { FadeInContent } from 'components/menu/menu.styles'
import { Anchor, Box, Text } from 'ui/core'
import { SkeletonLoader } from 'components/skeletonLoader/skeletonLoader'
import { SearchResult } from 'types'

export const SearchQueryResults = ({
  resultsName,
  res,
  useBorders,
  numResToShow = 5,
  loading,
  notFoundText = 'Ekkert fannst',
}: {
  resultsName: string
  res: SearchResult[]
  numResToShow?: number
  useBorders?: boolean
  loading: boolean
  notFoundText?: string
}) => (
  <FadeInContent>
    <Box px={3} pb={useBorders ? 3 : 1} width="full">
      <Text m={0} fontWeight="bold" variant="textLarge" color="dark500">
        {resultsName}
      </Text>
    </Box>
    <Box>
      {loading ? (
        <Box px={2}>
          {[...Array(5)].map((_, index) => (
            <SkeletonLoader
              key={`loading-placeholder-${resultsName}-${index}`}
              width="full"
              py={2}
              my={1}
            />
          ))}
        </Box>
      ) : (
        <Box>
          {res.length < 1 && (
            <Box px={3} py={1} width="full">
              <Text m={0} color="dark300">
                {notFoundText}
              </Text>
            </Box>
          )}
          {res.slice(0, numResToShow).map((res, idx) => (
            <SearchResultRow
              useBorder={useBorders}
              key={`search-categories-res-${idx}`}
              resultsName={resultsName}
              {...res}
            />
          ))}
        </Box>
      )}
    </Box>
  </FadeInContent>
)

const SearchResultRow = ({
  url,
  name,
  useBorder,
}: SearchResult & { resultsName: string; useBorder?: boolean }) => (
  <Box
    pt={1}
    pb={useBorder ? 2 : 0}
    px={3}
    width="full"
    borderTop={useBorder ? 'light' : 'none'}
  >
    <Anchor variant="dark" size={useBorder ? 'medium' : 'standard'} href={url}>
      {name}
    </Anchor>
  </Box>
)
