import { Box } from 'ui/core';
import { HeroBoxPanelTwoWrapper, HeroWrapper } from './frontpageDuoHero.styles';
import { HeroBox } from './heroBoxProps/heroBoxProps';
import { FeaturedProducts } from './featuredProducts/featuredProducts';

export type FrontpageDuoHeroProps = {
  panelOneTitle: string;
  panelOneText: string;
  panelOneImage: string;
  panelOneButtonText: string;
  panelOneButtonLink: string;
  panelTwoTitle: string;
  panelTwoText: string;
  panelTwoImage: string;
  panelTwoButtonText: string;
  panelTwoButtonLink: string;
  featuredProductsTitle: string;
  featuredProductsIds: string;
};
export const FrontpageDuoHero = ({
  panelOneTitle,
  panelOneText,
  panelOneImage,
  panelOneButtonText,
  panelOneButtonLink,
  panelTwoTitle,
  panelTwoText,
  panelTwoImage,
  panelTwoButtonText,
  panelTwoButtonLink,
  featuredProductsTitle,
  featuredProductsIds,
}: FrontpageDuoHeroProps) => (
  <HeroWrapper
    width="full"
    display="flex"
    flexWrap="wrap"
    justifyContent="space-between"
    alignItems="center"
    pb={[10, 12, 12]}
  >
    <Box
      display={['none', 'none', 'none', 'flex']}
      height="full"
      width={['0', '0', '0', '7/12']}
      flexDirection="column"
      pr={[0, 0, 0, 2]}
    >
      <Box
        height={['6/10', '6/10', '6/10', '6/10', '6/10', '7/10']}
        width="full"
        position="relative"

      >
        <img src={panelOneImage} style={{ width: "100%", height: "100%", objectFit: "cover" }} alt='' />
        <HeroBox
          zIndex="2"
          position="absolute"
          right={-80}
          top={[8, 8, 8, 8, 8, 20]}
          title={panelOneTitle}
          text={panelOneText}
          buttonText={panelOneButtonText}
          buttonLink={panelOneButtonLink}
        />
      </Box>
      <Box
        height={['4/10', '4/10', '4/10', '4/10', '4/10', '3/10']}
        width="full"
      >
        <FeaturedProducts
          title={featuredProductsTitle}
          productIds={featuredProductsIds.split(',')}
        />
      </Box>
    </Box>
    <Box
      position="relative"
      height="full"
      width={['full', 'full', 'full', '5/12']}
      pl={[0, 0, 0, 2]}
    >
      <img src={panelTwoImage} style={{ width: "100%" }} alt='' />

      <HeroBoxPanelTwoWrapper
        width="full"
        position="absolute"
        bottom={-50}
        right={[0, 0, 0, 0, 8, 14]}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <HeroBox
          title={panelTwoTitle}
          text={panelTwoText}
          buttonText={panelTwoButtonText}
          buttonLink={panelTwoButtonLink}
        />
      </HeroBoxPanelTwoWrapper>
    </Box>
  </HeroWrapper>
);
