import styled from 'styled-components'
import { Box } from 'ui/core'

export const StyledMenu = styled(Box)``

interface MobileMenuContainerProps {
  active?: boolean
}
export const MobileMenuContainer = styled(Box)<MobileMenuContainerProps>`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    left: ${({ active }) => (active ? 0 : 100)}%;
    transition: all 200ms ease-in;
  }
`
export const MobileHeaderContainer = styled(Box)`
  margin: 6px 0;
`
