import { ThemeProvider } from 'styled-components'
import { getTheme } from '../ui/theme'
import { ThemeVariant } from 'types'
import SimplybookWidget from 'libs/simplyBookWidget'
import { useEffectOnce } from 'react-use'

interface Props {
  site: ThemeVariant
}

export const SimplyBookApp = ({ site }: Props) => {
  const theme = getTheme(site)
  const widget = new SimplybookWidget({
    widget_type: 'iframe',
    url: 'https://husasmidjan.simplybook.it',
    theme: 'space',
    theme_settings: {
      timeline_hide_unavailable: '1',
      sb_base_color: '#d42853',
      hide_past_days: '0',
      timeline_show_end_time: '0',
      timeline_modern_display: 'as_slots',
      display_item_mode: 'block',
      sb_review_image: '',
      dark_font_color: '#474747',
      light_font_color: '#ffffff',
      sb_company_label_color: '#ffffff',
      hide_img_mode: '0',
      show_sidebar: '1',
      sb_busy: '#dad2ce',
      sb_available: '#d3e0f1',
    },
    timeline: 'modern',
    datepicker: 'top_calendar',
    is_rtl: false,
    app_config: { allow_switch_to_ada: 0, predefined: [] },
  })
  useEffectOnce(() => {
    widget.start()
  })

  return (
    <ThemeProvider theme={theme}>
      <iframe
        scrolling="no"
        className="sb-widget-iframe"
        width="100%"
        frameBorder="0"
        src={widget.getUrl()}
        name={widget.name}
        title={widget.name}
        id={widget.name}
      ></iframe>
    </ThemeProvider>
  )
}
