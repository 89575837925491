import { FC } from 'react'
import { AncestorNode } from 'types/rentDevice'
import { Anchor, Box } from 'ui/core'

export const Breadcrumbs: FC<{ title: string; ancestors: AncestorNode[] }> = ({
  title,
  ancestors,
}) => (
  <Box display="flex" alignItems="center">
    {ancestors &&
      ancestors.length > 0 &&
      ancestors.map(({ Name, Url }, index) => (
        <Box
          mr={2}
          key={Url}
          display={[
            index < ancestors.length - 1 ? 'none' : 'inherit',
            index < ancestors.length - 1 ? 'none' : 'inherit',
            'inherit',
          ]}
        >
          <Anchor
            href={Url}
            size="medium"
            fontWeight="medium"
            variant="light"
            iconRight="chevronRight"
            iconSize="16"
          >
            {Name}
          </Anchor>
        </Box>
      ))}
    <Box mr={2}>
      <Anchor size="medium" fontWeight="bold">
        {title}
      </Anchor>
    </Box>
  </Box>
)
