import { SearchInput } from 'components/searchInput/searchInput'
import { useProductSearch } from 'helpers'
import { useUmbracoSearch } from 'helpers/hooks/useUmbracoSearch'
import { FC, useRef, useState } from 'react'
import { useDebounce } from 'react-use'
import { Box, Hidden } from 'ui/core'
import { HeaderButton } from '../headerControls/headerControls'
import { Dimmer } from './headerSearchInput.styles'
import { SearchDropdown } from './searchDropdown/searchDropdown'
import { SearchModal } from './searchModal/searchModal'

export const HeaderSearchInput: FC = () => {
  const debounce = 300
  const ref = useRef<HTMLInputElement | null>(null)
  const [query, setQuery] = useState('')
  const { results, status, value, setValue, postSearchResult } =
    useProductSearch()
  const { categories, pages, categoriesLoading, pagesLoading } =
    useUmbracoSearch(value)
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false)

  // Debounced search value by default
  useDebounce(
    () => {
      if (query !== value) setValue(query)
    },
    debounce,
    [query],
  )

  const setInputFocus = () => {
    if (ref && ref.current) {
      ref.current.focus()
    }
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent={['flex-start', 'flex-end', 'space-between']}
        alignItems="center"
        height="full"
        position="relative"
      >
        <Dimmer
          width="viewportWidth"
          height="viewportHeight"
          position="fixed"
          bg="dark400"
          isSearching={status === 'fulfilled' || status === 'loading'}
          zIndex="-1"
          display={['none', 'none', 'none', 'inherit']}
        />
        <Hidden below="lg" height="full" width="full">
          <Box
            width={['full', 'full', 'full', 'full', '8/12', '7/12']}
            position="relative"
            style={{ top: 5 }}
            pl={1}
          >
            <SearchInput
              noFocusBorder
              ref={ref}
              autoComplete="off"
              placeholder="Leita..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  window.location.href = '/leit?q=' + query
                }
              }}
            />
          </Box>
          <Box
            height="full"
            onClick={() => setInputFocus()}
            display="flex"
            alignItems="center"
            justifyContent="center"
            px={[1, 2, 4]}
            borderRight="light"
          >
            <HeaderButton
              iconLeft="search"
              iconColor="primary300"
              hiddenBelow="md"
            />
          </Box>
        </Hidden>
        <Hidden above="lg" px={[1, 2]}>
          <SearchModal
            query={query}
            loading={status === 'loading'}
            isSearching={mobileSearchOpen}
            setIsSearching={setMobileSearchOpen}
            setQuery={setQuery}
            productResults={results || null}
            categoryResults={categories || null}
            pagesResults={pages || null}
            productsLoading={status === 'loading'}
            categoriesLoading={categoriesLoading}
            pagesLoading={pagesLoading}
            postSearchResult={postSearchResult}
            total={
              (results?.count || 0) +
              (categories?.count || 0) +
              (pages?.count || 0)
            }
          />
        </Hidden>
      </Box>
      <Box
        right={[10, 10, 10, 20, 'auto']}
        position="relative"
        width={['full', 'full', 'full', 'full', '8/12', '7/12']}
      >
        <SearchDropdown
          query={query}
          closeSearch={() => !mobileSearchOpen && setQuery('')}
          searching={status === 'fulfilled' || status === 'loading'}
          productResults={results || null}
          categoryResults={categories || null}
          pagesResults={pages || null}
          productsLoading={status === 'loading'}
          categoriesLoading={categoriesLoading}
          pagesLoading={pagesLoading}
          postSearchResult={postSearchResult}
          total={
            (results?.count || 0) +
            (categories?.count || 0) +
            (pages?.count || 0)
          }
        />
      </Box>
    </>
  )
}
