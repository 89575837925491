import { formatCurrency } from 'helpers';
import { FC, useState } from 'react';
import { RentDevice } from 'types/rentDevice';
import {
	Box,
	Text,
	GridColumn,
	Figure,
	GridRow,
	Icon,
	ImageBox,
} from 'ui/core';
import {
	AvailabilityIndicator,
	AvailabilityModalWrapper,
	ImageWrapper,
} from './rentDeviceBox.styles';

interface RentDeviceBoxProps {
	device: RentDevice;
}

export const RentDeviceBox: FC<RentDeviceBoxProps> = ({ device }) => {
	const [showAvailabilityModal, setShowAvailabilityModal] =
		useState<boolean>(false);

	const rentLocationPhoneNumbersMap: any = {
		'01': '525 3188', // Skútuvogur
		'02': '525 3500', // Hafnafjörður
		'03': '525 3750', // Reykjanesbær
		'04': '525 3102', // Grafarholt
		'05': '525 3770', // Vestmannaeyjar
		'06': '525 3700', // Selfoss
		'09': '525 3550', // Akureyri
		'10': '525 3790', // Hvolsvöllur
		'12': '-', // Dalvík
		'15': '525 3330', // Akranes
		'18': '664 3659', // Húsavík
		'19': '525 3390', // Höfn Hornafirði
	};

	return (
		<>
			{showAvailabilityModal && (
				<AvailabilityModalWrapper
					zIndex="header"
					position="fixed"
					width="viewportWidth"
					height="viewportHeight"
					py={6}
					px={[2, 2, 4]}
					top={0}
					left={-5}
					display="flex"
					justifyContent="center"
					alignItems="center"
					show={showAvailabilityModal}
					onClick={() => setShowAvailabilityModal(false)}
				>
					<Box
						bg="white"
						py={4}
						px={[4, 4, 6, 10]}
						position="relative"
						width={[
							'full',
							'full',
							'10/12',
							'8/12',
							'6/12',
							'5/12',
						]}
					>
						<Box position="absolute" top={2} right={2}>
							<Icon type="close" size="24" color="dark200" />
						</Box>
						<Text
							variant="textLarge"
							fontWeight="bold"
							color="dark500"
							pb={4}
						>
							Þetta tæki er til á leigu í eftirfarandi verslunum
						</Text>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							width="full"
							mb={3}
						>
							<Box
								display="flex"
								width={['5/12', '5/12', '6/12']}
							>
								<Text
									variant="textSmall"
									color="dark400"
									fontWeight="bold"
									m={0}
									p={0}
								>
									Staðsetning
								</Text>
							</Box>
							<Box
								display="flex"
								width={['3/12', '3/12', '3/12']}
							>
								<Text
									variant="textSmall"
									color="dark400"
									fontWeight="bold"
									m={0}
									p={0}
								>
									Símanúmer
								</Text>
							</Box>
							<Box
								display="flex"
								justifyContent="flex-start"
								width={['4/12', '4/12', '3/12']}
							>
								<Text
									variant="textSmall"
									color="dark400"
									fontWeight="bold"
									m={0}
									pl={[1, 5, 6, 5, 6, 8]}
								>
									Laust
								</Text>
							</Box>
						</Box>
						{device.RentLocations.map((location) => (
							<Box
								key={location.LocationId}
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								width="full"
							>
								<Box
									display="flex"
									alignItems="center"
									width={['5/12', '5/12', '6/12']}
								>
									<Text
										variant="textSmall"
										fontWeight="medium"
										color="dark400"
										m={0}
										p={0}
										mb={1}
									>
										{/* Format names - they're weird in the AX */}
										{location.LocationId === '01'
											? 'Skútuvogur'
											: location.LocationId === '02'
											? 'Hafnafjörður'
											: location.LocationName}
									</Text>
								</Box>
								<Box
									display="flex"
									alignItems="center"
									width={['3/12', '3/12', '3/12']}
								>
									<Text
										variant="textSmall"
										fontWeight="medium"
										color="dark400"
										m={0}
										p={0}
										mb={1}
									>
										<a
											href={`tel:${
												rentLocationPhoneNumbersMap[
													location.LocationId
												] || '-'
											}`}
										>
											{rentLocationPhoneNumbersMap[
												location.LocationId
											] || '-'}
										</a>
									</Text>
								</Box>
								<Box
									display="flex"
									justifyContent="flex-start"
									alignItems="center"
									width={['4/12', '4/12', '3/12']}
									m={0}
									pl={[1, 5, 6, 5, 6, 8]}
									mb={1}
								>
									<AvailabilityIndicator
										available={location.DevicesIn > 0}
										borderRadius="circle"
									/>
									<Text
										variant="textSmall"
										color="dark300"
										m={0}
										p={0}
										ml={1}
									>
										{location.DevicesIn > 0
											? location.DevicesIn + ' tæki'
											: 'Ekki laust'}
									</Text>
								</Box>
							</Box>
						))}
					</Box>
				</AvailabilityModalWrapper>
			)}
			<GridColumn pushBottom width={['full', 'full', 'full', '1/2']}>
				<Box
					height="full"
					border="light"
					p={[2, 2, 4]}
					my={2}
					width="full"
				>
					<ImageWrapper
						width="full"
						display="flex"
						justifyContent="center"
						borderBottom="light"
						p={[0, 0, 0, 4]}
					>
						{device.Images &&
						Array.isArray(device.Images) &&
						device.Images.length > 0 ? (
							<ImageBox
								imgSrc={
									'https://www.husa.is/' + device.Images[0]
								}
							/>
						) : (
							<Box width="full">
								<Box
									py={[8, 10, 12]}
									display="flex"
									alignItems="center"
									justifyContent="center"
									opacity={0.5}
								>
									<Figure
										type="logoHusa"
										size={[55, 60, 75]}
									/>
								</Box>
							</Box>
						)}
					</ImageWrapper>
					<Box width="full" p={[2, 2, 2, 2, 4]}>
						<Text variant="h4" color="black" fontWeight="bold">
							{device.ItemName}
						</Text>
						<Box width="full" pb={2}>
							<Text color="dark300">{device.Description}</Text>
						</Box>
						<GridRow overflow="visible">
							<GridColumn width="full">
								<Text
									fontWeight="bold"
									variant="textLarge"
									color="black"
								>
									Leiguverð
								</Text>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									width="full"
								>
									<Text
										variant="textSmall"
										fontWeight="medium"
										color="dark400"
										mr={1}
									>
										5 klst
									</Text>
									<Text variant="textSmall" color="dark300">
										{formatCurrency(device.HalfDayPrice)} kr
									</Text>
								</Box>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									width="full"
								>
									<Text
										variant="textSmall"
										fontWeight="medium"
										color="dark400"
										mr={1}
									>
										24 klst.
									</Text>
									<Text variant="textSmall" color="dark300">
										{formatCurrency(device.DayPrice)} kr
									</Text>
								</Box>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									width="full"
								>
									<Text
										variant="textSmall"
										fontWeight="medium"
										color="dark400"
										mr={1}
									>
										Vika
									</Text>
									<Text variant="textSmall" color="dark300">
										{formatCurrency(device.WeekPrice)} kr
									</Text>
								</Box>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									width="full"
								>
									<Text
										variant="textSmall"
										fontWeight="medium"
										color="dark400"
										mr={1}
									>
										Viðbótadagur
									</Text>
									<Text variant="textSmall" color="dark300">
										{formatCurrency(device.HalfDayPrice)} kr
									</Text>
								</Box>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									width="full"
								>
									<Text
										variant="textSmall"
										fontWeight="medium"
										color="dark400"
										mr={1}
									>
										Trygging
									</Text>
									<Text variant="textSmall" color="dark300">
										0 kr
									</Text>
								</Box>
							</GridColumn>
							<GridColumn width="full">
								<Box
									mt={2}
									height="full"
									width="full"
									borderTop="light"
								>
									<Text
										pt={3}
										fontWeight="bold"
										variant="textLarge"
										color="black"
									>
										Staðsetningar
									</Text>
									{device.RentLocations.slice(0, 2).map(
										(location) => (
											<Box
												key={location.LocationId}
												display="flex"
												alignItems="center"
												justifyContent="space-between"
												width="full"
											>
												<Box
													display="flex"
													alignItems="center"
													width={[
														'5/12',
														'5/12',
														'6/12',
													]}
												>
													<Text
														variant="textSmall"
														fontWeight="medium"
														color="dark400"
														m={0}
														p={0}
														mb={1}
													>
														{/* Format names - they're weird in the AX */}
														{location.LocationId ===
														'01'
															? 'Skútuvogur'
															: location.LocationId ===
															  '02'
															? 'Hafnafjörður'
															: location.LocationName}
													</Text>
												</Box>
												<Box
													display="flex"
													alignItems="center"
													justifyContent="center"
													width={[
														'3/12',
														'3/12',
														'3/12',
													]}
												>
													<Text
														variant="textSmall"
														fontWeight="medium"
														color="dark400"
														m={0}
														p={0}
														mb={1}
													>
														<a
															href={`tel:${
																rentLocationPhoneNumbersMap[
																	location
																		.LocationId
																] || '-'
															}`}
														>
															{rentLocationPhoneNumbersMap[
																location
																	.LocationId
															] || '-'}
														</a>
													</Text>
												</Box>
												<Box
													display="flex"
													alignItems="center"
													justifyContent="flex-end"
													width={[
														'auto',
														'auto',
														'6/12',
													]}
													m={0}
													p={0}
													mb={1}
												>
													<AvailabilityIndicator
														available={
															location.DevicesIn >
															0
														}
														borderRadius="circle"
													/>
													<Text
														variant="textSmall"
														color="dark300"
														m={0}
														p={0}
														ml={1}
													>
														{location.DevicesIn > 0
															? location.DevicesIn +
															  ' ' +
															  (location.DevicesIn >
															  1
																	? 'laus'
																	: 'laust') +
															  ' tæki'
															: 'Ekki laust'}
													</Text>
												</Box>
											</Box>
										)
									)}
									{device.RentLocations.length > 2 && (
										<Box
											role="button"
											pt={2}
											style={{ cursor: 'pointer' }}
											onClick={() =>
												setShowAvailabilityModal(true)
											}
										>
											<Text
												variant="textSmall"
												color="iskraft"
												fontWeight="medium"
											>
												Sjá allar staðsetningar
											</Text>
										</Box>
									)}
								</Box>
							</GridColumn>
						</GridRow>
						{/* TODO: Later when we've enabled rent system for rent devices online */}
						{/* <Box width="full" display="flex" pt={2}>
          <Button size="small" iconRight="cart" variant="primary">
            Leigja vöru
          </Button>
        </Box> */}
					</Box>
				</Box>
			</GridColumn>
		</>
	);
};
