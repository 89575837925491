import { CategorySlider } from 'components'
import { provideCategorySliderData } from 'helpers'
import { ThemeProvider } from 'styled-components'
import { getTheme } from '../ui/theme'
import { Box, Text } from 'ui/core'

export const CategorySliderApp = () => {
  const slider = provideCategorySliderData()
  const theme = getTheme(slider.theme)
  return (
    <ThemeProvider theme={theme}>
      <Box width="full" py={4}>
        {slider.title && (
          <Text mx={1} variant="h4" fontWeight="semiBold" color="black">
            {slider.title}
          </Text>
        )}
        <CategorySlider {...slider} />
      </Box>
    </ThemeProvider>
  )
}
