import { HeaderMobileControls } from 'components/header/headerControls/headerControls'
import { FC, useState } from 'react'
import { MenuNode, ThemeVariant } from 'types'
import { Box } from 'ui/core'
import { MobileMenuContainer } from './mobileMenu.styles'
import { MobileMenuScreen } from './mobileMenuScreen'

interface Props {
  menus: MenuNode[]
  theme: ThemeVariant
  toggleContentMenu: () => void
  toggleUserMenu: () => void
  isLoggedIn: boolean
  userName: string
}

export const MobileMenu: FC<Props> = ({
  menus,
  theme,
  isLoggedIn,
  userName,
  toggleContentMenu,
  toggleUserMenu,
}) => {
  const [activeMenu, setActiveMenu] = useState<MenuNode | null>(null)
  const [activeCategory, setActiveCategory] = useState<MenuNode | null>(null)
  return (
    <Box
      height="viewportHeight"
      overflowY="scroll"
      overflowX="hidden"
      width="full"
      display="flex"
      flexWrap="nowrap"
      justifyContent="flex-start"
      alignItems="stretch"
      position="relative"
      pt={[6, 6, 8]}
    >
      {/* First level menu */}
      <MobileMenuContainer
        bg="white"
        active={true}
        width="full"
        height="full"
        position="absolute"
        zIndex="1"
      >
        <MobileMenuScreen
          theme={theme}
          items={menus}
          itemActive={activeMenu?.Name}
          onClick={(item) => {
            setActiveMenu(item)
            setActiveCategory(null)
          }}
          contentTop={
            <HeaderMobileControls
              theme={theme}
              isLoggedIn={isLoggedIn && userName.length > 0}
              userName={userName}
              toggleContentMenu={toggleContentMenu}
              toggleUserMenu={toggleUserMenu}
            />
          }
        />
      </MobileMenuContainer>
      {/* 2nd level menu */}
      <MobileMenuContainer
        bg="white"
        active={activeMenu !== null}
        width="full"
        height="full"
        position="absolute"
        zIndex="2"
      >
        {activeMenu && (
          <MobileMenuScreen
            items={activeMenu.Children || []}
            itemActive={activeCategory?.Name}
            parentCategory={activeMenu.Name}
            parentUrl={activeMenu.Url}
            goBack={() => {
              setActiveMenu(null)
              setActiveCategory(null)
            }}
            onClick={(item) => setActiveCategory(item)}
          />
        )}
      </MobileMenuContainer>
      {/* 3rd level menu */}
      <MobileMenuContainer
        bg="white"
        active={activeCategory !== null}
        width="full"
        height="full"
        position="absolute"
        zIndex="3"
      >
        {activeCategory && (
          <MobileMenuScreen
            items={activeCategory.Children || []}
            goBack={() => setActiveCategory(null)}
            parentCategory={activeCategory.Name}
            parentUrl={activeCategory.Url}
          />
        )}
      </MobileMenuContainer>
    </Box>
  )
}
