import * as layout from './layout'
import { getAnchorTheme } from './anchors'
import { getButtonStyles } from './buttons'
import { getBorderTheme } from './borders'
import * as fonts from './fonts'
import * as colors from './colors'
import { zIndices } from './zIndices'
import { AllPallettes, Palette } from './palettes'
import { ThemeVariant } from '../../types'

export const theme = (palette: Palette) => {
  const buttonStyles = getButtonStyles(palette)
  const anchorStyles = getAnchorTheme(palette)
  const borderStyles = getBorderTheme(palette)
  return {
    breakpoints: layout.breakpoints,
    space: layout.space,
    grid: layout.grid,
    sizes: layout.sizes,
    fonts: fonts.fonts,
    fontSizes: fonts.fontSizes,
    fontWeights: fonts.fontWeights,
    lineHeights: fonts.lineHeights,
    fontVariants: fonts.fontVariants,
    ...buttonStyles,
    ...anchorStyles,
    ...borderStyles,
    colors: {
      ...colors,
      ...palette,
    },
    zIndices,
  }
}
export const DefaultTheme = theme(AllPallettes.husa)
export type ThemeInterface = typeof DefaultTheme

export const getTheme = (variant: ThemeVariant) => {
  const palette = AllPallettes[variant || 'husa']
  return theme(palette)
}

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeInterface {}
}
