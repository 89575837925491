import { Box, Anchor, Text, BoxProps } from 'ui/core'

type Props = {
  variant?: 'white' | 'primary' | 'dark'
  title: string
  text: string
  buttonText: string
  buttonLink: string
}
export const HeroBox = ({
  variant = 'white',
  title,
  text,
  buttonText,
  buttonLink,
  ...boxProps
}: Props & BoxProps) => (
  <Box
    {...boxProps}
    py={[2, 2, 4]}
    pl={[4, 4, 8]}
    pr={[8, 8, 12]}
    boxShadow="menu"
    bg="white"
    style={{ maxWidth: 510 }}
  >
    <Text variant="h3" fontWeight="semiBold" color="primary300" pb={1}>
      {title}
    </Text>
    <Box style={{ maxWidth: 250 }}>
      <Text variant="textLarge" color="black">
        {text}
      </Text>
    </Box>
    {buttonLink && (
      <Box pt={1} pb={2}>
        <Anchor href={buttonLink} variant="dark-bold">
          {buttonText || 'Nánar'}
        </Anchor>
      </Box>
    )}
  </Box>
)
