import { ReactElement } from 'react'
import { ProductSearchResultsResponse, SearchResultsResponse } from 'types'
import { Box, Container, Hidden } from 'ui/core'
import { ModalBase } from 'ui/core/modalBase/modalBase'
import { zIndices } from 'ui/theme'
import { MobileSearch } from '../mobileSearch/mobileSearch'

interface Props {
  baseId: string
  disclosure?: ReactElement
  ariaLabel?: string
  isVisible?: boolean
  loading: boolean
  isSearching: boolean
  setIsSearching: (isSearching: boolean) => void
  query: string
  setQuery: (q: string) => void
  searchLink: string
  productResults: ProductSearchResultsResponse | null
  categoryResults: SearchResultsResponse | null
  pagesResults: SearchResultsResponse | null
  productsLoading: boolean
  categoriesLoading: boolean
  pagesLoading: boolean
  total: number
  postSearchResult: (productId: string) => void
}

export const Modal = ({
  baseId,
  disclosure,
  setIsSearching,
  isVisible,
  ...props
}: Props) => {
  return (
    <ModalBase
      baseId={baseId}
      disclosure={disclosure}
      zIndex={zIndices.header - 2}
      onVisibilityChange={(isVisible) => setIsSearching(isVisible)}
      backdropBg="rgba(26, 61, 95, 0.4)"
      position="centered"
    >
      {({ closeModal }: { closeModal: () => void }) => (
        <Hidden above="lg" px={[1, 2]}>
          <Box
            position="relative"
            width="viewportWidth"
            height="viewportHeight"
            overflowY="scroll"
            bg="white"
          >
            <Container>
              <MobileSearch closeModal={closeModal} {...props} />
            </Container>
          </Box>
        </Hidden>
      )}
    </ModalBase>
  )
}
