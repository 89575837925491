import { FC } from 'react'
import { AncestorNode } from 'types/rentDevice'
import { Box, Text } from 'ui/core'
import { Breadcrumbs } from './breadcrumbs/breadcrumbs'

export const PageHeader: FC<{ title: string; ancestors: AncestorNode[] }> = ({
  title,
  ancestors,
}) => (
  <Box width="full" pt={16} pb={8}>
    <Text variant="h3" fontWeight="bold" color="black" mb={4}>
      {title || ''}
    </Text>
    <Breadcrumbs title={title || ''} ancestors={ancestors} />
  </Box>
)
