import { MenuNode } from 'types/megamenu'

export const provideCategorySliderData = () => {
  const CATEGORY_SLIDER_DATA = (window as any).__CATEGORY_SLIDER_DATA
  const { theme, title, categoriesData } = CATEGORY_SLIDER_DATA
  var categories: MenuNode[] = []
  try {
    categories = JSON.parse(categoriesData)
  } catch (e) {
    // Invalid json
    console.log(e)
  }
  return {
    theme,
    categories,
    title,
  }
}
