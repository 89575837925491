import { FC } from 'react'
import {
  HamburgerLine1,
  HamburgerLine2,
  HamburgerLine3,
  IOSPlaceholder,
  StyledHamburger,
} from './hamburger.styles'

interface Props {
  onClick: () => void
  isOpen: boolean
}

export const Hamburger: FC<Props> = ({ onClick, isOpen }) => {
  return (
    <StyledHamburger
      onClick={onClick}
      open={isOpen}
      aria-label="Hamborgara menu"
    >
      <IOSPlaceholder />
      <HamburgerLine1 />
      <HamburgerLine2 />
      <HamburgerLine3 />
    </StyledHamburger>
  )
}
