import axios from 'axios'
import { FC, useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { ThemeVariant } from 'types'
import { Account } from 'types/account'
import { Anchor, Box, Button, Icon, Text } from 'ui/core'
import { AccountSwitch } from './userDropdown.styles'

export const UserDropdown: FC<{
  theme: ThemeVariant
  userName: string
  isOpen?: boolean
  accounts: Account[] | null
  accountsLoading: boolean
}> = ({ isOpen, theme, accounts, accountsLoading }) => {
  const [height, setHeight] = useState<string | number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const ref = useRef(null)
  useEffect(() => {
    if (!isOpen && height !== 0) setHeight(0)
    else if (isOpen && height === 0) setHeight('auto')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  const logout = async () => {
    setLoading(true)
    axios.post(`/umbraco/surface/login/logout`).then(() => {
      window.location.reload()
      setLoading(false)
    })
  }
  const switchAccount = async (selectedAccount: string) => {
    setLoading(true)
    axios
      .post(`/umbraco/api/account/SetAccount`, { selectedAccount })
      .then(() => {
        window.location.reload()
        setLoading(false)
      })
  }
  return (
    <Box width="full" position="relative">
      <Box
        ref={ref}
        height="full"
        width="full"
        position={['fixed', 'fixed', 'absolute']}
        right={[0, 0, 0, -45]}
        zIndex="header"
        style={{ width: '450px' }}
      >
        <AnimateHeight duration={400} height={height}>
          <Box px={4} pt={6} pb={4} bg="white" width="full">
            <Box py={1}>
              <Anchor
                href="https://vidskiptavefur.husa.is/"
                variant="dark"
                fontWeight="medium"
              >
                Þjónustuvefur
              </Anchor>
            </Box>
            <Box py={1}>
              <Anchor
                href={
                  theme === ThemeVariant.BLOMA
                    ? '/blomaval/verslanir/'
                    : '/upplysingar/afgreidslutimi/'
                }
                variant="dark"
                fontWeight="medium"
              >
                Afgreiðslutímar
              </Anchor>
            </Box>
            <Box py={1}>
              <Anchor variant="dark" fontWeight="medium">
                Hafa samband
              </Anchor>
            </Box>
            <Box
              py={4}
              width="full"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {accountsLoading ||
                (accounts && accounts.length > 0 && (
                  <Box width="full">
                    <Box pb={1} mb={1} width="full" borderBottom="light">
                      <Anchor
                        fontWeight="black"
                        href="https://vidskiptavefur.husa.is/"
                      >
                        Mínir reikningar
                      </Anchor>
                    </Box>
                    {accountsLoading ? (
                      <Box>Sæki reikninga...</Box>
                    ) : (
                      accounts.map((account, index) => (
                        <AccountSwitch
                          key={account.SSN}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() =>
                            !account.Selected
                              ? switchAccount(account.AccountNum)
                              : null
                          }
                        >
                          <Text
                            fontWeight={account.Selected ? 'medium' : 'regular'}
                            py={1}
                            m={0}
                            color={account.Selected ? 'dark500' : 'dark300'}
                          >
                            {account.SSN}
                          </Text>
                          <Box width="full" textAlign="right">
                            <Text
                              fontWeight={
                                account.Selected ? 'medium' : 'regular'
                              }
                              variant="textSmall"
                              color={account.Selected ? 'dark500' : 'dark300'}
                              py={1}
                              m={0}
                            >
                              {account.Category.length > 30
                                ? account.Category.slice(0, 28) + '...'
                                : account.Category}
                            </Text>
                          </Box>
                          <Box
                            height="full"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            py={1}
                            opacity={account.Selected ? 1 : 0}
                          >
                            <Icon
                              type="checkmark"
                              size="18"
                              color="primary300"
                            />
                          </Box>
                        </AccountSwitch>
                      ))
                    )}
                  </Box>
                ))}
            </Box>

            <Box pt={4} display="flex" width="full" justifyContent="center">
              <Button onClick={logout} size="small" variant="primary">
                {loading ? '...' : 'Útskráning'}
              </Button>
            </Box>
          </Box>
        </AnimateHeight>
      </Box>
    </Box>
  )
}
