import { FC } from 'react'
import { Table as T, Thead, Tbody, TD, TR, TH } from './table.styles'

interface Props {
  head: string[]
  data: string[][]
  loading?: boolean
}

export const Table: FC<Props> = ({ head, data, loading }) => (
  <T>
    <Thead>
      <TR>
        {head.map((th, index) => (
          <TH key={`th-${index}`} width={(10 / head.length) * 100}>
            {th}
          </TH>
        ))}
      </TR>
    </Thead>
    <Tbody>
      {loading && 'Sæki...'}
      {!loading &&
        data.map((row, index) => (
          <TR key={`tr-${index}`}>
            {head.map((th, index) => (
              <TD width={(10 / head.length) * 100}>
                <label>{th}: </label>
                {row[index] || ''}
              </TD>
            ))}
          </TR>
        ))}
    </Tbody>
  </T>
)
