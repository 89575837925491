import styled from 'styled-components'
import { media } from 'ui/utils/lib/mediaQuery'

export const StyledHeader = styled.header`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.header};
  box-shadow: 0px 2px 44px rgba(206, 206, 206, 0.409562);

  ${media.mobile} {
    height: 54px;
  }
`
