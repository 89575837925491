import { provideFooterData } from 'helpers'
import { ThemeProvider } from 'styled-components'
import { getTheme } from '../ui/theme'
import { FooterComponent } from 'components/footer/footer'

export const FooterApp = () => {
  const footer = provideFooterData()
  const theme = getTheme(footer.theme)
  return (
    <ThemeProvider theme={theme}>
      <FooterComponent footer={footer} />
    </ThemeProvider>
  )
}
