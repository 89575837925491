import React from 'react'
import { Settings } from 'react-slick'
import styled, { css } from 'styled-components'
import { Box, Hidden, Icon, Inline } from 'ui/core'

export const ArrowsWrapper = styled(Box)`
  cursor: pointer;
  pointer-events: none;
`
export const ArrowWrapper = styled(Box)<{
  variant?: 'light' | 'dark' | 'ghost'
}>`
  ${({ variant }) =>
    variant !== 'ghost' &&
    css`
      height: 70px;
      width: 80px;
    `};
  cursor: pointer;
  pointer-events: auto;
`

interface ArrowProps {
  onClick?:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined
  iconType: 'arrowLeft' | 'arrowRight'
  variant?: 'light' | 'dark' | 'ghost'
}
const Arrow = ({ iconType, onClick, variant = 'dark' }: ArrowProps) => {
  return (
    <ArrowWrapper
      role="button"
      border={variant === 'light' ? 'light' : 'none'}
      alia-label="category-slider-next-slide"
      onClick={onClick}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg={
        variant === 'light'
          ? 'white'
          : variant === 'dark'
          ? 'primary300'
          : 'transparent'
      }
      variant={variant}
      borderRadius="small"
    >
      <Icon
        type={iconType}
        color={variant === 'dark' ? 'white' : 'primary300'}
        size="24"
      />
    </ArrowWrapper>
  )
}

export const getSliderSettings = (
  settings: Settings,
  handleNext: () => void,
  handlePrev: () => void,
): Settings => ({
  ...settings,
  arrows: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  touchMove: true,
  swipe: true,
  accessibility: false,
  swipeToSlide: true,
  infinite: false,
  dots: true,
  appendDots: () => {
    return (
      <ArrowsWrapper
        position="absolute"
        top={[-70, -70, -70, 0]}
        left={[0, 0, 0, 185]}
        height="full"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Hidden below="lg">
          <Inline space={1}>
            <Arrow variant="light" iconType="arrowLeft" onClick={handlePrev} />
            <Arrow variant="dark" iconType="arrowRight" onClick={handleNext} />
          </Inline>
        </Hidden>
        <Hidden above="lg">
          <Inline space={2}>
            <Arrow variant="ghost" iconType="arrowLeft" onClick={handlePrev} />
            <Arrow variant="ghost" iconType="arrowRight" onClick={handleNext} />
          </Inline>
        </Hidden>
      </ArrowsWrapper>
    )
  },
  autoplay: false,
  draggable: true,
  variableWidth: true,
})
